/*!
 * Bootstrap v4.0.0-alpha.6 (https://getbootstrap.com)
 * Copyright 2011-2017 The Bootstrap Authors
 * Copyright 2011-2017 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

// Core variables and mixins
@import "variables";
@import "mixins";
@import "custom";

// Reset and dependencies
@import "normalize";
@import "print";

// Core CSS
@import "reboot";
@import "type";
@import "images";
@import "code";
@import "grid";
@import "forms";
@import "buttons";

// Components
@import "transitions";

// Utility classes
@import "utilities";
