.contact-help{
  margin: -345px 0 100px;
  @include media-breakpoint-down('md'){
    margin: 50px 0;
  }
  &__content{
    background-color: $color-white;
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    justify-content: space-between;
    border-radius: 41px;
    padding: 84px 130px 127px;
    box-shadow: $box-shadow-blue-large;
    position: relative;
    border: 4px solid #ffffff;
    @include media-breakpoint-down('lg'){
      padding: 50px 80px 100px;
    }
    @include media-breakpoint-down('md'){
      padding: 40px 50px;
    }
    @include media-breakpoint-down('sm'){
      padding: 20px 25px;
    }
    &:after, &:before {
      top: 100%;
      left: 50%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }
    &:after {
      border-color: rgba(255, 255, 255, 0);
      border-top-color: #ffffff;
      border-width: 20px;
      margin-left: -20px;
    }
    &:before {
      border-color: rgba(255, 255, 255, 0);
      border-top-color: #ffffff;
      border-width: 26px;
      margin-left: -26px;
    }
  }
  &__image{
    min-height: 129px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    @include media-breakpoint-down('md'){
      min-height: initial;
    }
  }
  &__tile{
    width: 33%;
    @include media-breakpoint-down('md'){
      width: 100%;
      margin-bottom: 40px;
    }
  }
  h2{
    width: 100%;
    margin: 0 0 73px;
    @include media-breakpoint-down('md'){
      margin: 0 0 40px;
    }
  }
  h4{
    margin: 0 0 22px;
  }
  img{
    margin: 0 0 29px;
    @include media-breakpoint-down('md'){
      width: 100%;
      max-width: 65px;
    }
  }
  p{
    margin: 0 0 10px;
    &+p{
      margin: 0;
    }
  }
  .btn{
    margin: 35px 0 0 0;
  }
}