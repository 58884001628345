.breadcrumbs{
  margin-top: -296px;
  position: relative;
  z-index: 3;
  padding: 15px 0 7px;
  color: $color-white;
  @include media-breakpoint-down(md) {
    margin-top: 0;
    color: $color-text;
    background-color: $color-gray-semi;
    padding: 15px 0 18px;
  }
  @include media-breakpoint-down(xs) {
    padding: 18px 0;
  }
  a,span{
    font-size: 12px;
    line-height: 15px;
    display: inline-block;
    @include media-breakpoint-down(xs) {
      font-size: 10px;
      line-height: 12px;
    }
  }
  a{
    font-family: 'Gilroy-ExtraBold';
    text-decoration: underline;
    color: $color-white;
    transition: $transition-base;
    @include media-breakpoint-down(md) {
      color: $color-accent-dark;
    }
    &:hover{
      opacity: .8;
      @include media-breakpoint-down(md) {
        color: $color-accent;
      }
    }
  }
  span{
    margin-left: -2px;
  }
}