@mixin newsGradient() {
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 1) 0%,
    rgba(233, 244, 254, 1) 100%
  );
}
.news {
  margin: 63px 0 0;
  padding-bottom: 131px;
  @include newsGradient();
  @include media-breakpoint-down(md) {
    margin: 50px 0 0;
    padding-bottom: 100px;
  }
  @include media-breakpoint-down(sm) {
    margin: 20px 0 0;
    padding-bottom: 70px;
  }
  @include media-breakpoint-down(xs) {
    margin: 45px 0 0;
    padding-bottom: 50px;
  }
  &.version-2 {
    background: #fff;
    padding-bottom: 0;
    &.gradient {
      @include newsGradient();
    }
    .news__content {
      flex-wrap: wrap;
      @include media-breakpoint-down(sm) {
        box-shadow: 0px 3px 20px 0 rgba(0, 0, 0, 0.16);
      }
    }
    .news__footer {
      padding-top: 0;
      width: 100%;
      .btn.btn-secondary {
        font-size: 14px;
      }
    }
    .news__highlight {
      .news__item {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        @include media-breakpoint-down(sm) {
          padding-bottom: 30px;
        }
      }
    }
    .break {
      flex-basis: 100%;
      height: 0;
    }
    .news__item__action {
      position: static;
      margin: 37px 0;
      @include media-breakpoint-down(sm) {
        margin: 20px 0;
      }
    }
    .news__item {
      padding-bottom: 0;
      @include media-breakpoint-down(sm) {
        display: flex;
        flex-direction: row;
        padding: 20px 24px;
        width: 100%;
        .image-link {
          background-size: cover;
        }
        .news__item__text {
          padding: 0 10px;
        }
        & > * {
          align-items: center;
          display: flex;
          width: 50%;
        }
      }
      &:nth-child(2) {
        @include media-breakpoint-down(lg) {
          display: flex !important;
        }
      }
    }
    .hide-mobile {
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }
    .news__list {
      padding-bottom: 0;
      @include media-breakpoint-down(sm) {
        box-shadow: none;
        padding: 0;
      }
    }
  }
  &__header {
    text-align: center;
    margin-bottom: 32px;
    .promotion-title {
      padding-left: 27px;
      @include media-breakpoint-down(sm) {
        padding-left: 0;
      }
    }
    .news-title {
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }
  }
  &__wrapper {
    min-width: 63%;
  }
  &__content {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    background-color: $color-white;
    box-shadow: 0px 3px 20px 0 rgba(0, 0, 0, 0.16);
    border-radius: 41px;
    @include media-breakpoint-down(sm) {
      flex-wrap: wrap;
    }
    @include media-breakpoint-down(sm) {
      box-shadow: none;
    }
    .header-mobile {
      display: none;
      @include media-breakpoint-down(sm) {
        display: block;
        text-align: center;
        min-width: 100%;
        margin-top: 40px;
      }
    }
  }
  &__highlight {
    max-width: 400px;
    min-width: 400px;
    @include media-breakpoint-down(lg) {
      max-width: 50%;
      min-width: 50%;
    }
    @include media-breakpoint-down(sm) {
      max-width: 100%;
      min-width: 100%;
    }
    .news__item {
      box-shadow: 10px 10px 20px 0 rgba(0, 138, 207, 0.16);
      background-color: #1796d5;
      border-radius: 29px;
      max-width: 100%;
      height: 100%;
      position: relative;
      padding-bottom: 80px;
    }
    .news__item__text {
      color: $color-white;
      padding: 20px 38px 20px 23px;
      @include media-breakpoint-down(md) {
        padding: 15px 5px 15px 5px;
      }
      @include media-breakpoint-down(xs) {
        padding: 15px 10px 0;
      }
      a {
        color: $color-white;
      }
      small {
        color: $color-white;
      }
    }
  }
  &__list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-left: 12px;
    padding-right: 14px;
    position: relative;
    padding-bottom: 80px;
    @include media-breakpoint-down(lg) {
      max-width: 50%;
      min-width: 50%;
      padding-left: 0;
      padding-right: 0;
      justify-content: center;
    }
    @include media-breakpoint-down(sm) {
      max-width: 100%;
      min-width: 100%;
      margin-top: 30px;
      background-color: $color-white;
      box-shadow: 0px 3px 20px 0 rgba(0, 0, 0, 0.16);
      border-radius: 29px;
    }
  }
  &__item {
    text-align: center;
    max-width: 371px;
    padding: 40px 0px 0;
    @include media-breakpoint-down(lg) {
      max-width: 100%;
    }
    @include media-breakpoint-down(md) {
      padding: 10px 25px 0;
    }
    @include media-breakpoint-down(sm) {
      padding: 30px 25px 0;
    }
    @include media-breakpoint-down(xs) {
      padding: 15px 10px 0;
    }
    &:nth-child(2) {
      @include media-breakpoint-down(lg) {
        display: none;
      }
    }
    .image-link {
      border-radius: 11px;
      display: block;
      overflow: hidden;
      max-width: 341px;
      height: 180px;
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: center;
      margin: 0 auto;
      transition: $transition-base;
      box-shadow: 5px 5px 10px 0 rgba(232, 48, 138, 0);
      position: relative;
      &::after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: $color-accent;
        opacity: 0;
        transition: $transition-base;
      }
      @include media-breakpoint-down(xs) {
        height: 120px;
        max-width: 275px;
      }
      &:hover {
        box-shadow: 5px 5px 10px 0 rgba(232, 48, 138, 0.3);
        &::after {
          opacity: 0.5;
        }
      }
    }
    &__text {
      padding: 20px 10px 20px 17px;
      @include media-breakpoint-down(md) {
        padding: 15px 5px 15px 5px;
      }
      @include media-breakpoint-down(xs) {
        padding: 15px 0 0;
      }
      a {
        color: $color-text;
        transition: $transition-base;
        &:hover {
          color: $color-accent;
          text-decoration: none;
        }
      }
      h4 {
        line-height: 20px;
      }
      small {
        font-size: 11px;
        line-height: 17px;
        margin-bottom: 9px;
        display: block;
        color: #a2a7a9;
        @include media-breakpoint-down(sm) {
          margin-bottom: 5px;
        }
      }
      p {
        font-size: 14px;
        line-height: 20px;
      }
    }
    &__action {
      width: 100%;
      text-align: center;
      margin-top: 9px;
      margin-bottom: 30px;
      position: absolute;
      bottom: 0;
      left: 0;
      @include media-breakpoint-down(xs) {
        margin-bottom: 20px;
      }
    }
  }
}
