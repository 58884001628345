//Colors

$color-primary	                : #0BBBEF;
$color-primary-dark	            : #008ACF;

$color-accent	                : #E8308A;
$color-accent-dark	            : #B80E80;

$color-background	            : #fff;

$color-text                     : #485156;
$color-text-accents             : #636363;
$color-text-white               : #fff;

$color-text-link                : #B80E80;
$color-text-link-hover          : #E8308A;

$color-form-error-text          : #c82626;
$color-form-error-input         : #f4918f;
$color-form-error-input-border  : #bf4945;



// uzywane
$color-white                    :#fff;
$color-blue                     :#1796D5;
$color-red                      :#D43381;
$color-sidebar                  :#AB1685;
$color-timeline-circles         :#CF1E85;
$color-timeline-circles-hover   :#CF1E85;
$color-discount-hover           :#D01F85;
$color-border-faq               :#EBEBEB;
//$color-border-gray              : #dedede;
//$color-border-gray-hover        : #bababa;
//$color-black                    : #000;
//$color-gray                     : #8d8d8d;
//$color-text-gray-light          : #F5F5F5;
//$color-text-gray                : #585858;
//$color-link-hover-dark          : #585858;
//$color-link-blue                : #9cd2f5;

$color-gray-meddium             :#6f6f6f;
$color-gray-light               :#F5F5F5;
$color-gray-semi               :#E9F4FE;
$color-article-text             :#383838;
$color-text-small               :#A2A7A9;

//$color-gradient-start           :#215c88;
//$color-gradient-end             :#e9055d;
//
$screen-xxs-max                 :479px;
//$input-color-placeholder        :#ffffff;

$transition-base:       all .4s ease-in-out !default;
$box-shadow-blue:       5px 5px 10px 0 rgba(11, 187, 239, .3);
$box-shadow-blue-large: 10px 10px 40px 0 rgba(11, 187, 239, .3);
$box-shadow-red:        5px 5px 10px 0 rgba(232, 48, 138, .3);












