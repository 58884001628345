.recommend-earnings-section {
  margin: 82px 0 0 0;
  position: relative;

  @include media-breakpoint-down(md) {
    margin: 0;
    background-color: $color-blue;
  }

  &__graphic {
    height: 750px;
    margin-top: -46px;
    position: relative;
    padding-bottom: 140px;
    overflow: hidden;
    width: 100%;
    background-color: $color-blue;

    @include media-breakpoint-down(md) {
      height: 700px;
    }

    @include media-breakpoint-down(sm) {
      height: 1300px;
    }

    &::after {
      content: '';
      position: absolute;
      right: 0;
      bottom: 0;
      height: 140px;
      width: 100%;
      background-color: #fff;
      z-index: 1;
      @include media-breakpoint-down(md) {
        display: none;
      }
    }
  }

  .decor {
    position: absolute;
    width: 100%;
    height: 100%;

    & > span {
      position: absolute;
    }

    .icon-hero_maziaj_left {
      left: -265px;
      top: 60px;
      font-size: 560px;
      @include media-breakpoint-down(sm) {
        left: -525px;
      }
      @include media-breakpoint-down(xs) {
        left: -630px;
      }
    }

    .icon-hero_maziaj_right {
      right: -325px;
      top: -740px;
      font-size: 1200px;
      z-index: 0;
      width: 933px;
      height: 1200px;
      @include media-breakpoint-down(sm) {
        right: -420px;
      }

      .path4 {
        position: absolute;
        right: 0;
        top: 0;

        &::before {
          position: relative;
          z-index: 2;
        }
      }
    }
  }

  .container {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    text-align: center;
    padding: 50px;

    .row {
      align-items: center;
      flex-direction: column;
      height: 83%;
      justify-content: space-around;

      @include media-breakpoint-down(md) {
        height: 100%;
      }

      @include media-breakpoint-down(sm) {
        flex-direction: row;
      }

      div p {
        font-weight: bold;
      }

      .header, .bottom {
        color: $color-white;
      }

      .header {
        h2 {
          margin: 0 0 20px;
        }

        p {
          font-size: 20px;

          &:first-of-type {
            margin: 0 0 10px;
          }

          &:last-of-type {
            font-size: 17px;
          }
        }
      }

      .content {
        display: flex;
        flex-direction: row;

        @include media-breakpoint-down(sm) {
          flex-direction: column;
          width: 100%;
        }

        div {
          border-radius: 40px;
          background: white;
          padding: 40px 0;
          margin: 0 20px;
          width: 350px;

          @include media-breakpoint-down(lg) {
            padding: 20px 0;
            width: 260px;
          }

          @include media-breakpoint-down(md) {
            margin: 20px 20px;
            width: 200px;
          }

          @include media-breakpoint-down(sm) {
            margin: 20px auto;
            padding: 40px 0;
            width: 90%;
          }

          img {
            margin: 0 0 15px 0;
          }

          p {
            font-size: 18px;

            &:first-of-type {
              margin: 0 0 5px;
            }
          }
        }
      }

      .bottom {
        p {
          font-size: 17px;

          &:first-of-type {
            margin: 0 0 10px;
          }
        }
      }
    }
  }
}