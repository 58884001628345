.table {
  border: 1px solid #ddd;
  thead{
    th{
      font-family: 'Gilroy-ExtraBold';
      &:last-child{
        min-width: 120px;
      }
    }
  }
  tbody{
    tr{
      td{
        border-bottom: none;
        & + td {
          border-left: 1px solid #ddd;
        }
      }
    }
  }
  td{
    vertical-align: baseline;
  }
}