.footer{
  margin-top: -80px;
  position: relative;
  z-index: 1;
  padding-top: 117px;
  background-color: $color-text;
  color: $color-white;
  @include media-breakpoint-down(xs) {
    margin-top: 0;
    padding-top: 35px;
  }
  a{
    color: $color-white;
    transition: $transition-base;
    &:hover{
      color: $color-accent;
      text-decoration: none;
    }
  }
  h4{
    margin-bottom: 18px;
    @include media-breakpoint-down(sm) {
      margin-bottom: 15px;
    }
  }
  .socials{
    margin-top: -40px;
    margin-bottom: 15px;
    @include media-breakpoint-down(sm) {
      margin-top: -10px;
      margin-bottom: 15px;
    }
    @include media-breakpoint-down(xs) {
      margin-top: -10px;
    }
  }
  &__map{
    @include media-breakpoint-down(sm) {
      order: 3;
    }
  }
  &__app{
    @include media-breakpoint-down(sm) {
      order: 2;
    }
  }
  &__tel{
    @include media-breakpoint-down(sm) {
      order: 1;
    }
  }
  &__list{
    ul{
      margin: 32px 0 57px;
      list-style-type: none;
      padding: 0;
      @include media-breakpoint-down(sm) {
        margin: 15px 0 30px;
      }
      li{
        & + li{
          margin-top: 6px;
        }
      }
    }
    &--first{
      @include media-breakpoint-down(sm) {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
      }
      @include media-breakpoint-down(xs) {
        display: block;
      }
    }
    &--socials{
      @include media-breakpoint-down(sm) {
        width: 100%;
        margin-bottom: 30px;
      }
      @include media-breakpoint-down(xs) {
        padding-left: 0;
        width: 100%;
      }
      h4{
        margin-bottom: 32px;
        @include media-breakpoint-down(sm) {
          margin-bottom: 15px;
        }
      }
    }
    &--app{
      display: inline-block;
      vertical-align: top;
      max-width: 222px;

      display: none;
      @include media-breakpoint-down(xs) {
        margin-bottom: 30px;
        max-width: 100%;
      }
      h4{
        margin-bottom: 32px;
        @include media-breakpoint-down(sm) {
          margin-bottom: 15px;
        }
      }
      a{
        margin-top: 36px;
        display: block;
        @include media-breakpoint-down(xs) {
          margin-top: 20px;
          display: inline-block;
        }
      }
    }
    &--info{
      text-align: right;
      @include media-breakpoint-down(md) {
        text-align: left;
      }
      @include media-breakpoint-down(sm) {
        margin-bottom: 30px;
      }
      h4{
        margin-bottom: 13px;
        @include media-breakpoint-down(sm) {
          margin-bottom: 15px;
        }
      }
      .btn.btn-primary{
        margin-top: 21px;
        padding: 20px 72px 20px 21px;
        font-size: 22px;
        margin-bottom: 20px;
        border-radius: 50px;
        @include media-breakpoint-down(lg) {
          margin-top: 9px;
          padding: 16px 63px 15px 21px;
          font-size: 13px;
          margin-bottom: 10px;
          border-radius: 25px;
        }
        &:hover, &:active, &:focus{
          .icon{
            width: 100%;
          }
        }
        .icon{
          width: 55px;
          right: 0;
          border-radius: 50px;
          @include media-breakpoint-down(lg) {
            width: 46px;
            right: 0;
            border-radius: 25px;
          }
          i {
            right: 20px;
            font-size: 16px;
            @include media-breakpoint-down(lg) {
              right: 16px;
            }
          }
        }
      }
      h5{
        margin-top: 15px;
      }
      small{
        display: block;
        margin-top: 21px;
      }
    }
  }
  &__phone{
    display: inline-block;
    vertical-align: top;
    margin-left: 66px;
    margin-top: -6px;

    display: none;
    @include media-breakpoint-down(lg) {
      margin-left: 40px;
    }
    @include media-breakpoint-down(md) {
      display: none;
    }
  }
  .copyright{
    margin-top: 60px;
    padding: 18px 0 14px;
    text-align: left;
    font-size: 11px;
    color: $color-text-small;
    background-color:$color-text;
    @include media-breakpoint-down(sm) {
      margin-top: 40px;
    }
    a{
      color: $color-text-small;
      &:hover{
        color: $color-accent;
      }
    }
  }
}