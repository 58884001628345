.reviews{

  padding: 200px 0 27px;
  text-align: center;
  @include media-breakpoint-down(lg) {
    padding: 100px 0 27px;
  }
  @include media-breakpoint-down(md) {
    padding: 60px 0 27px;
  }
  &__content{
    max-width: 995px;
    margin: 55px auto 30px;
    position: static;
    @include media-breakpoint-down(lg) {
      max-width: 850px;
    }
    @include media-breakpoint-down(sm) {
      margin: 25px auto 30px;
    }
  }
  &__item{
    background-color: $color-white;
    border-radius: 41px;
    box-shadow: 5px 5px 20px 0 rgba(0, 138, 207, 0.3);
    padding: 61px 30px 42px;
    @include media-breakpoint-down(sm) {
      padding: 40px 15px 25px;
    }
    &__wrapper{
      padding: 15px;
      &.focused {
        width: 100%;
      }
    }
    &.focused {
      background: #E9F4FE;
      .icon-quotation_mark {
        &:before {
          color: #fff;
        }
      }
    }
    h3{
      color: $color-primary;
      position: relative;
      display: inline-block;
      margin-bottom: 24px;
      @include media-breakpoint-down(sm) {
        margin-bottom: 15px;
      }
      span{
        position: absolute;
        left: -47px;
        top: -28px;
        font-size: 35px;
        @include media-breakpoint-down(sm) {
          left: -30px;
          top: -15px;
          font-size: 20px;
        }
      }
    }
    p{
      line-height: 20px;
      @include media-breakpoint-down(sm) {
        margin-bottom: 10px
      }
      strong{
        font-size: 16px;
        line-height: 23px;
      }
    }
  }
}