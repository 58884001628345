.points-of-sale{
  margin-top: 30px;
  ul{
    margin-top: 60px;
    padding-left: 15px;
    column-count: 2;
    column-gap: 0;
    @include media-breakpoint-down(sm) {
      column-count: unset;
      margin-top: 40px;
    }
    li{
      a{
        color: $color-text;
        transition: $transition-base;
        font-family: 'Gilroy-ExtraBold';
        font-size: 16px;
        &:hover{
          color: $color-accent;
          text-decoration: none;
        }
      }
      & + li {
        margin-top: 10px;
      }
    }
  }
}