#cookie-bar * {
  margin: 0px;
  padding: 0px;
}

#cookie-bar {
  align-items: center;
  background-color: #fff;
  border: 1px solid #dcdbd6;
  border-bottom: none;
  border-radius: 5px;
  bottom: 5px;
  box-shadow: 0 0 6px #dcdbd6;
  display: flex;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  left: 0px;
  justify-content: center;
  margin: 0 auto;
  max-width: 1240px;
  padding: 20px;
  position: fixed;
  right: 0px;
  width: 90%;
  z-index: 1000;
}

#cookie-bar .caption {
  color: #48484a;
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
}

#cookie-bar .message {
  color: #86827b;
}

#cookie-bar .cookies__link {
  display: inline-block;
  font-size: 12px;
}

#cookie-bar .cookies__closeBtn {
  background: #e8308a;
  border-radius: 5px;
  color: #fff;
  font-size: 14px;
  height: 40px;
  line-height: 40px;
  margin-left: 20px;
  min-width: 140px;
  text-align: center;
}

#cookie-bar .cookies__closeBtn:hover {
  background: #b80e80;
}

#cookie-bar .cookies__link:hover {
  color: #e8308a;
  text-decoration: underline;
}

@media all and (max-width: 768px) {
  #cookie-bar {
    flex-direction: column;
  }

  #cookie-bar .cookies__closeBtn {
    margin: 20px auto 0;
    max-width: 150px;
  }
}

.ep-cookies-info-wrapper {
  .btn {
    min-width: 0;
    padding: 15px 21px;
  }
  .ep-modal-dialog.ep-modal-dialog.ep-modal-dialog {
    width: 975px;
    @media screen and (max-width: 980px) {
      width: 90%;
    }
  }
  .ep-modal-title {
    background: transparent linear-gradient(90deg, #aa1b87 0%, #2588ce 100%) 0%
      0% no-repeat padding-box;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    height: 5px;
  }
  .ep-modal-content.ep-modal-content.ep-modal-content.ep-modal-content.ep-modal-content {
    color: #48484a;
    padding: 45px 40px 20px;

    h3 {
      font-size: 18px;
      font-weight: 700;
      line-height: (21px/18px) * 1em;
      margin-bottom: 15px;
    }
  }
  [data-section] {
    display: none;
  }
  [data-select-section] {
    display: none !important;
  }

  [data-visible-section="main"] {
    [data-section="main"] {
      display: block;
    }
    [data-select-section="instructions"] {
      display: inline-block !important;
    }
  }

  [data-visible-section="instructions"] {
    [data-section="instructions"] {
      display: block;
      @media screen and (max-width: 768px) {
        max-height: 224px;
        overflow-x: hidden;
        overflow-y: scroll;
        padding: 0 15px 0 0;
      }
    }
    [data-select-section="main"] {
      display: inline-block !important;
    }
    .ep-cookies-content {
      font-size: 14px;
      line-height: (21px/14px) * 1em;
      .browsers-list {
        padding-top: 40px;
        @media screen and (max-width: 768px) {
          flex-direction: column;
          padding-top: 10px;
        }
      }
      .ep-grid-col {
        text-align: center;
        @media screen and (max-width: 768px) {
          padding: 10px 0 10px 62px;
          position: relative;
          text-align: left;
          .icon {
            position: absolute;
            left: 10px;
            top: 10px;
          }
        }
      }
      .text {
        font-weight: 700;
      }
      .icon {
        img {
          height: 36px;
          width: 36px;
        }
      }
    }
  }

  .highlight {
    color: $color-accent-dark;
    font-family: "Gilroy-Bold";
  }

  .additional-description {
    padding: 30px 0 0 0;
  }

  p {
    margin: 0;
  }

  .ep-modal-footer {
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 768px) {
      display: block;
      text-align: left !important;
      & > * {
        margin: 5px 0;
      }
    }
    .btn {
      min-width: 0;
      font-family: "Gilroy-Light";
      width: auto;
    }
    .ep-cancel-btn {
      border: none;
    }
    .ep-ok-btn {
      padding: 8px;
    }
  }
}

#klaro-cookies-modal {
  &,
  * {
    box-sizing: border-box;
  }

  .cookie-modal,
  .cookie-notice {
    background: transparentize(#000, 0.6);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999;
  }

  .cn-body,
  .cm-modal {
    background: white;
    &::before {
      background: transparent linear-gradient(90deg, #aa1b87 0%, #2588ce 100%)
        0% 0% no-repeat padding-box;
      content: "";
      display: block;
      height: 5px;
      position: absolute;
      top: 0;
      width: 100%;
    }
    border: 1px solid $gray-border;
    display: block;
    left: 50%;
    position: fixed;
    top: auto;
    transform: translateX(-50%);
    width: 1000px;
    padding-top: 26px;
    margin: 30px 0;
    border-radius: 4px;
    max-width: 90%;

    @include media-breakpoint-down(sm) {
      margin: 2vh 0;
      width: 94%;
    }
  }

  .cm-modal {
    display: flex;
    flex-direction: column;
    max-height: 90vh;

    @include media-breakpoint-down(sm) {
      height: 96vh;
    }
  }

  .cn-body > p {
    padding: 0 40px 40px 40px;
    margin: 0;

    @include media-breakpoint-down(sm) {
      padding-left: 20px;
      padding-right: 20px;
    }

    .title {
      margin-bottom: 20px;
      display: block;
    }
  }

  .cm-header {
    padding: 0 40px 20px 40px;

    @include media-breakpoint-down(sm) {
      padding-left: 20px;
      padding-right: 20px;
    }

    .hide {
      background: transparent;
      border: 0 none;
      stroke: $color-text;
      min-width: 40px;
      position: absolute;
      top: 15px;
      right: 10px;
    }
  }

  .cm-body {
    flex-grow: 1;
    overflow: auto;
    padding: 0 40px 20px 40px;

    @include media-breakpoint-down(sm) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  .title,
  .cm-list-title {
    font-size: 18px;
    color: $color-text;
    font-weight: 700;
  }

  .cm-purposes {
    padding: 0;
    margin: 0;
    list-style-type: none;
  }

  .cm-services,
  .cm-toggle-all {
    display: none;
  }

  .cm-btn-success {
    @extend .btn-secondary;
    @extend .btn;

    min-width: 0;
    padding-right: 21px;

    &.cm-btn-accept {
      @extend .btn-primary;
    }
  }

  .cn-decline,
  .cm-powered-by {
    display: none;
  }

  .cn-ok,
  .cm-footer-buttons {
    border-top: $gray-border solid 1px;
    padding: 20px;

    @include media-breakpoint-down(sm) {
      padding-top: 10px;
      padding-bottom: 10px;
    }

    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .cm-footer-buttons {
    @include media-breakpoint-down(sm) {
      flex-direction: column;
      align-items: start;

      .cm-btn-info.cm-btn-success {
        margin-bottom: 10px;
        padding-left: 0;

        &:hover {
          background: transparent;
          color: $color-text-link;
        }
      }
    }
  }

  .cn-ok {
    .cm-btn.cm-btn-success {
      @extend .btn-primary;
      @extend .btn;
      min-width: 0;
      padding-right: 21px;
    }
    .cm-link.cn-learn-more {
      @extend .btn-primary;
      @extend .btn;
      min-width: 0;
      padding-right: 21px;
    }
  }
  .cm-list-description {
    margin: 0;
    max-width: 70%;
    padding: 11px 0 27px;
    text-align: inherit;

    @include media-breakpoint-down(sm) {
      max-width: 100%;
    }
  }

  .cm-list-input {
    display: none;

    + .cm-list-label {
      align-items: center;
      display: flex;
      justify-content: space-between;

      margin: 0;

      .cm-switch {
        border-radius: 16px;
        border: 1px solid $gray-border;
        background: white;
        cursor: pointer;
        display: block;
        height: 33px;
        width: 66px;
        padding: 4px 37px 5px 6px;
        position: relative;
        top: 45px;
        transition: padding 0.15s ease-in, background-color 0.15s ease-in;

        @include media-breakpoint-down(sm) {
          top: 0;
        }

        .slider {
          border-radius: 30px;
          background: $gray-border;
          height: 23px;
          width: 23px;
        }
      }
    }

    &:checked {
      + .cm-list-label {
        .cm-switch {
          background: $color-accent;
          border: 1px solid $color-accent;
          padding: 4px 6px 5px 37px;

          .slider {
            background: white;
          }
        }
      }
    }

    &.required {
      & + .cm-list-label {
        .cm-switch {
          background: $gray-border;
          border: 1px solid $gray-border;

          .slider {
            background: white;
          }
        }
      }
    }
  }
}
