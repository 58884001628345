.contact-bok {
  padding: 160px 0 175px;
  @include media-breakpoint-down('lg') {
    padding: 100px 0 120px;
  }
  @include media-breakpoint-down('md') {
    padding: 60px 0 80px;
  }

  &__subheader {
    font-size: 20px;
    line-height: 30px;
    margin: 0 0 40px;
    max-width: 540px;

    strong {
      color: $color-accent;
    }
  }

  &__text {
    margin-top: 115px;
    @include media-breakpoint-down('md') {
      margin-top: 40px;
    }
  }

  h2 {
    margin: 0 0 75px;
    @include media-breakpoint-down('md') {
      margin: 0 0 30px;
    }
  }

  .btn {
    margin: 40px 0 0 0;
  }
  p{
    a{
      color: $color-text;
      font-weight: bold;
      font-family: 'Gilroy-ExtraBold';
    }
  }
}