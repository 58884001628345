.search-banner{
  margin-top: 82px;
  @include media-breakpoint-down(md) {
    margin-top: 0;
    background-color: $color-blue;
  }
  &__content{
    margin-top: -80px;
    @include media-breakpoint-down(md) {
      padding-top: 60px;
      padding-bottom: 60px;
      margin-top: 0;
    }
  }
  .search{
    padding-top: 7px;
    @include media-breakpoint-down(md) {
      padding-top: 0;
    }
  }
  &__graphic{
    height: 348px;
    margin-top: -46px;
    position: relative;
    padding-bottom: 140px;
    overflow: hidden;
    width: 100%;
    background-color: $color-blue;
    @include media-breakpoint-down(md) {
      display: none;

      margin-top: 0;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      padding: 0;
    }
    &::after{
      content: '';
      position: absolute;
      right: 0;
      bottom: 0;
      height: 140px;
      width: 100%;
      background-color: #fff;
      z-index: 1;
      @include media-breakpoint-down(md) {
        display: none;
      }
    }
  }
  .decor{
    position: absolute;
    width: 100%;
    height: 100%;
    & > span{
      position: absolute;
    }
    .icon-hero_maziaj_left{
      left: -340px;
      top: -40px;
      font-size: 798px;
      @include media-breakpoint-down(sm) {
        left: -525px;
      }
      @include media-breakpoint-down(xs) {
        left: -630px;
      }
    }
    .icon-hero_maziaj_right{
      right: -325px;
      top: -740px;
      font-size: 1200px;
      z-index: 3;
      width: 933px;
      height: 1200px;
      @include media-breakpoint-down(sm) {
        right: -420px;
      }
      .path4{
        position: absolute;
        right: 0;
        top: 0;
        &::before{
          position: relative;
          z-index: 2;
        }
      }
    }
  }
}

