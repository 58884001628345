.routes-map{
  position: relative;
  margin-top: 0px;
  z-index: 3;
  .map{
    height: 890px;
    width: 100%;
    @include media-breakpoint-down(lg) {
      height: 950px;
    }
    @include media-breakpoint-down(md) {
      height: 835px;
    }
    @include media-breakpoint-down(sm) {
      height: 500px;
    }
    @include media-breakpoint-down(xs) {
      height: 360px;
    }
  }
}