@import 'core';
@import 'animate';
@import 'autoheight';
@import 'lazyload';

.owl-nav{
  position: absolute;
  left: 0;
  top: 54%;
  width: 100%;
  transform: translateY(-50%);
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  @include media-breakpoint-down(md) {
    width: 108%;
    margin-left: -29px;
  }
  & > button{
    background-color: $color-white;
    width: 41px;
    height: 41px;
    border-radius: 50%;
    border: none;
    transition: $transition-base;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover{
      background-color: $color-accent;
      span{
        &::before{
          color: $color-white;
        }
      }
    }
    span{
      font-size: 14px;
      line-height: 1;
      &::before{
        transition: $transition-base;
        color: $color-accent;
        display: inline-block;
      }
    }
  }
  .owl-prev{
    span{
      &::before{
        transform: rotate(-180deg);
      }
    }
  }
}
.owl-dots{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 16px 0;
  .owl-dot{
    transition: $transition-base;
    width: 21px;
    height: 21px;
    cursor: pointer;
    border-radius: 50%;
    background-color: $color-white;
    border: none;
    margin: 8px 4px;
    box-shadow: 0 4px 15px 0 rgba(0, 138, 207, 0.5);
    &.active, &:hover{
      background-color: $color-accent;
      box-shadow: 0 4px 15px 0 rgba(0, 138, 207, 0);
    }
  }
}