.recommend-program-section {
  position: relative;
  width: 100%;
  z-index: 3;
  margin-top: -295px;
  padding: 59px 0;
  @include media-breakpoint-down(xs) {
    padding: 14px 0 120px;
  }

  @include media-breakpoint-down(md) {
    margin: 20px 0 40px 0;
  }

  &__wrapper {
    background-color: $white;
    border-radius: 41px;
    padding: 70px;
    box-shadow: 10px 10px 71px -39px #00b9ea;
    width: 95%;
    margin: 0 auto;

    @include media-breakpoint-down(md) {
      padding: 30px;
    }

    .header h2 {
      text-align: center;
    }

    .content {
      margin: 60px 0 0 50px;

      @include media-breakpoint-down(lg) {
        margin: 60px 0;
      }

      @include media-breakpoint-down(md) {
        margin: 30px 20px;
      }

      .row {
        .col-md-12:first-of-type {
          display: flex;
          flex-direction: column;
          justify-content: space-around;

          div {
            width: 360px;

            @include media-breakpoint-down(md) {
              width: auto;
            }

            img {
              margin: 0 0 0 10px;
            }

            h3 {
              font-size: 30px;
              text-align: left;
              color: #00b9ea;
              margin: 20px 0 15px;

              @include media-breakpoint-down(md) {
                line-height: 28px;
              }
            }
          }
        }

        .col-md-12:last-of-type {
          div {
            height: 100%;

            img {
              height: auto;
              width: 100%;
            }
          }

          @include media-breakpoint-down(md) {
            padding: 30px;
          }
        }
      }
    }
  }
}