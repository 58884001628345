.work-space-v2 {
  padding: 102px 0 241px;
  background: linear-gradient(to bottom, rgba(255,255,255,1) 0%, rgba(233,244,254,1) 100%);
  @include media-breakpoint-down(lg) {
    padding: 102px 0 210px;
  }
  @include media-breakpoint-down(md) {
    padding: 60px 0 210px;
  }
  @include media-breakpoint-down(sm) {
    padding: 60px 0 140px;
  }
  @include media-breakpoint-down(xs) {
    padding: 80px 0 155px;
  }
  &__image{
    &__container{
      border-left: 1px solid $color-white;
      @include media-breakpoint-down(lg) {
        border: none;
      }
      @include media-breakpoint-down(sm) {
        margin-bottom: 80px;
      }
      @include media-breakpoint-down(xs) {
        margin-bottom: 40px;
      }
      & + .work-space-v2__image__container{
        border-left: 1px solid $color-white;
        @include media-breakpoint-down(sm) {
          border: none;
        }
      }
      &__content{
        max-width: 430px;
        margin: 0 auto;
        @include media-breakpoint-down(sm) {
          margin: 0 auto;
        }
        @include media-breakpoint-down(xs) {

        }
        .image{
          height: 281px;
          @include media-breakpoint-down(sm) {
            height: auto;
          }
        }
        h2{
          margin-bottom: 17px;
          position: relative;
        }
      }
    }
  }
  &__description {
    padding: 0 0 0 39px;
    @include media-breakpoint-down(lg) {
      padding: 0 0 90px 39px;
    }
    @include media-breakpoint-down(md) {
      padding: 0 0 90px 24px;
    }
    @include media-breakpoint-down(sm) {
      padding: 0 0 50px 24px;
    }
    @include media-breakpoint-down(xs) {
      padding: 0 0 30px 0;
    }
    p {
      max-width: 420px;
      margin-top: 12px;
    }
    img {
      height: auto;
      max-width: 460px;
      margin: 0 0 0 -17px;
      width: 100%;
      @include media-breakpoint-down(sm) {
        margin: 0;
      }
    }
  }
  &__routes {
    font-size: 18px;
    font-family: 'Gilroy-ExtraBold';
    font-weight: normal;
    line-height: 13px;
    max-height: 400px;
    padding: 5px 0;
    .route {
      display: flex;
      margin-bottom: 26px;
      text-decoration: none;
      i {
        margin: 0 10px;
        font-size: 14px;
      }
      &--pink {
        color: $color-accent-dark;
        &:hover {
          color: darken($color-accent-dark, 10%);
          span:last-child {
            color: darken($color-accent, 10%);
          }
        }
        span:last-child {
          color: $color-accent;
        }
      }
      &--blue {
        color: $color-primary-dark;
        &:hover {
          color: darken($color-primary-dark, 10%);
          span:last-child {
            color: darken($color-primary, 10%);
          }
        }
        span:last-child {
          color: $color-primary;
        }
      }
      &:last-of-type {
        margin-bottom: 10px;
      }
      &:hover {
        text-decoration: none;
      }
      .btn {
        margin-top: 3px;
      }
    }
    &--wrapper {
      background: $color-background;
      border-radius: 16px;
      box-shadow: $box-shadow-blue-large;
      display: block;
      margin: 19px 0 30px 0;
      height: 427px;
      padding: 19px 12px 19px 44px;
      @include media-breakpoint-down(xs) {
        height: auto;
      }
    }
  }
  &__routes-counter {
    color: #DDECF9;
    left: -45px;
    font-size: 67px;
    top: -25px;
    position: absolute;
    z-index: 0;
  }
  &__routes-header-content {
    position: relative;
  }
  .image{
    img{
      @include media-breakpoint-down(md) {
        margin: 0 auto 40px;
        display: block;
      }
      @include media-breakpoint-down(sm) {
        margin: 0;
      }
      @include media-breakpoint-down(xs) {
        margin: 0 auto 10px;
      }
    }
  }
}