.contact-section {
  position: relative;
  width: 100%;
  z-index: 3;
  margin-top: -295px;
  padding: 59px 0;
  @include media-breakpoint-down(md) {
    margin-top: 0;
    padding: 14px 0;
  }
  @include media-breakpoint-down(sm) {
    padding: 0;
  }
  &__wrapper{
    background-color: $white;
    border-radius: 41px;
    padding: 6px 58px 0;
    @include media-breakpoint-down(md) {
      padding: 6px 0 0;
    }
  }
  .tile-white {
    padding: 52px 70px 41px 35px;
    background: none;
    box-shadow: none;
    border-radius: 0;
    @include media-breakpoint-down(lg) {
      padding: 51px 50px 51px 50px;
    }
    @include media-breakpoint-down(md) {
      padding: 25px 25px 25px 25px;
      margin-top: 26px;
    }
    @include media-breakpoint-down(sm) {
      margin-top: 50px;
      padding: 0 0 55px;
    }
  }
  .left {
    padding: 51px 67px 0 0;
    @include media-breakpoint-down(md) {
      padding: 51px 15px 0 0;
    }
    @include media-breakpoint-down(sm) {
      padding: 45px 0 0;
    }
    .btn {
      margin: 15px 0 40px;
      @include media-breakpoint-down(sm) {
        margin: 10px 0 25px;
      }
    }
  }
  h3 {
    margin-bottom: 23px;
    @include media-breakpoint-down(sm) {
      margin-bottom: 20px;
    }
  }
  h4 {
    margin: 20px 0;
    color: $color-primary;
    i {
      font-size: 14px;
      margin-right: 8px;
      position: relative;
      top: 2px;
    }
  }
  p {
    margin-bottom: 1.1rem;
    @include media-breakpoint-down(sm) {
      margin-bottom: 10px;
    }
    small {
      font-size: 12px;
      line-height: 15px;
      display: block;
      color: $color-text-small;
    }
  }
}


body.contact {
  .info-banner__content__description {
    max-width: 446px;
    margin: 27px 154px 12px 0 !important;
    @include media-breakpoint-down(lg) {
      max-width: 360px;
      margin: 25px 75px 0 0 !important;
    }
    @include media-breakpoint-down(md) {
      max-width: 545px;
      margin: 25px 40px 0 0 !important;
    }
    h3 {
      margin: 0 0 38px;
      @include media-breakpoint-down(md) {
        margin: 0 0 15px;
      }
    }
    .btn {
      display: none;
    }
    p {
      margin-bottom: 19px;
      @include media-breakpoint-down(md) {
        margin-bottom: 10px;
      }
    }
    hr {
      border-color: transparent;
      margin: 23px 0;
      @include media-breakpoint-down(md) {
        margin: 15px 0;
      }
    }
  }
  .info-banner__image {
    img {
      margin-top: 28px;
      @include media-breakpoint-down(md) {
        margin-top: 15px;
      }
      @include media-breakpoint-down(sm) {
        margin-top: 0;
      }
    }
  }
  .info-banner.reversed .decor {
    bottom: -108px;
    right: 67%;
  }
}