.banner {
  position: relative;
  width: 100%;
  overflow: hidden;
  margin-top: -60px;
  @include media-breakpoint-down(md) {
    margin-top: 0;
  }

  .container {
    z-index: 2;
  }

  &__content {
    background: #0596d5;
    color: white;
    padding: 156px 0 170px;
    @include media-breakpoint-down(md) {
      padding: 0 0 70px;
    }
    @include media-breakpoint-down(sm) {
      padding: 0 0 25px !important;
    }

    &__text {
      @include media-breakpoint-down(md) {
        order: 2;
      }
    }
  }

  &__image {
    text-align: right;
    padding-bottom: 60px;
    @include media-breakpoint-down(lg) {
      padding: 0;
    }
    @include media-breakpoint-down(md) {
      text-align: center;
      margin-bottom: 30px;
    }
    @include media-breakpoint-down(sm) {
      display: none;
    }
    &.mobile-only {
      display: none;
      @include media-breakpoint-down(sm) {
        display: block !important;
      }
    }
    img {
      left: -55px;
      position: relative;
      @media (max-width: 1220px) {
        left: 0;
      }
      @include media-breakpoint-down(md) {
        left: 0;
      }
    }
    svg {
      max-width: 100%;
      height: auto;
      image {
        max-width: 100%;
        height: auto;
      }
    }
    .lights {
      display: none;
    }
  }

  &__shield-icon {
    position: absolute;
    right: 33px;
    top: -40px;
    z-index: 1;
    @include media-breakpoint-down(md) {
      top: 0;
    }
  }

  &__promotions {
    background: #fec363;
    border-radius: 20px;
    margin: 15px 0 9px;
    padding: 15px 15px 15px 104px;
    position: relative;
    width: 480px;
    @include media-breakpoint-down(sm) {
    //   padding: 13px 17px;
      width: 100%;
    }
    &__title {
      color: $color-accent-dark;
      font-family: "Gilroy-ExtraBold";
      font-size: 18px;
      line-height: 1;
    }
    &__buttons {
      padding: 10px 0 0;
      // @include media-breakpoint-down(sm) {
      //   padding: 10px 0 0 104px;
      // }
      // @media all and (max-width: 320px) {
      //   padding: 10px 0 0 90px;
      // }
      .btn {
        min-width: 122px;
        margin-right: 5px;
        padding-top: 8px;
        padding-bottom: 8px;
        @include media-breakpoint-down(sm) {
          margin-bottom: 8px;
        }
      }
      .split {
        @include media-breakpoint-down(sm) {
          display: block;
          padding: 2px 0 0 0;
        }
      }
    }
    &__image {
      bottom: -2px;
      height: 105px;
      left: 18px;
      position: absolute;
      width: 76px;
    }
  }
  &__promotions-disclaimer {
    font-size: 10px;
  }

  .inner {
    position: relative;
    z-index: 1;
    padding-left: 14px;
    max-width: 490px;
    [lang="en"] & {
      max-width: 525px;
    }
    @include media-breakpoint-down(xs) {
      padding-left: 0;
    }

    .cycle-content {
      position: relative;
      height: 40px;
      width: 295px;
      display: inline-block;
      @include media-breakpoint-down(sm) {
        height: 32px;
        width: 305px;
      }

      #main-scroll-headline {
        position: absolute;
        top: 0;
        height: 56px;
        width: 100%;
        overflow: hidden;
        @include media-breakpoint-down(sm) {
          height: 42px;
        }

        span {
          display: block;
          position: absolute;
          left: 0;
          top: -100%;

          &.anim-item {
            transition: 1.4s;
          }

          &.active {
            top: 0;
          }

          &.top-item {
            top: 100%;
          }
        }
      }
    }

    h1 {
      margin-bottom: 25px;
      font-size: 40px;
    }

    p {
      margin-bottom: 26px;
    }
  }

  .left-deco {
    position: absolute;
    overflow: hidden;
    font-size: 729px;
    right: 64%;
    bottom: -63px;
    transform: rotate(11deg);
    @include media-breakpoint-down(md) {
      font-size: 480px;
      right: 61%;
      bottom: 33px;
    }
  }

  .right-deco {
    font-size: 1078px;
    position: absolute;
    left: 55%;
    top: -180px;
    transform: rotate(-37deg);
    @include media-breakpoint-down(md) {
      font-size: 695px;
      top: 0;
    }
  }
}

.bar,
.bar-codes {
  background: white;
  border-radius: 23px;
  font-size: 0;
  line-height: 0;
  font-size: 0;
  width: max-content;
  overflow: hidden;
  display: block;
  max-width: 420px;
  margin-left: -10px;
  @include media-breakpoint-down(xs) {
    margin-left: 0;
  }

  > div {
    display: inline-block;
    vertical-align: top;
  }

  &__icon {
    font-size: 9px;
    letter-spacing: normal;
    line-height: 1;
    text-align: center;
    position: relative;
    width: 77px;
    min-height: 68px;

    em {
      font-style: normal;
      color: $color-accent;
      font-size: 9px;
      top: 45px;
      left: 14px;
      position: absolute;
      font-family: "Gilroy-ExtraBold";
    }

    i {
      font-size: 30px;
      position: absolute;
      top: 12px;
      left: 17px;

      &:before {
        color: $color-accent;
      }
    }
  }

  &__text {
    line-height: 1.1;
    color: $color-text;

    min-width: 243px;
    font-family: "Gilroy-ExtraBold";
    font-weight: normal;
    font-size: 16px;
    max-width: 460px;
    min-height: 68px;
    position: relative;
    letter-spacing: 1px;
    @include media-breakpoint-down(xs) {
      min-width: 205px;
      font-size: 13px;
    }

    > div {
      position: absolute;
      left: 0;
      top: 54%;
      transform: translateY(-50%);
    }

    span {
      display: block;
      max-height: 34px;
      overflow: hidden;
    }
  }

  &__button {
    font-size: 10px;
    line-height: 1;
    padding: 14px 16px 13px 10px;

    .link {
      cursor: pointer;
      display: block;
      background: $color-accent;
      width: 41px;
      height: 41px;
      border-radius: 41px;
      text-align: center;
      line-height: 41px;
      text-decoration: none;
    }
  }
}
.bar {
  &.tarcza-hopera {
    background: $color-accent-dark;
    color: white;
    &:hover {
      .link {
        background: white;
      }
    }
    .bar__text {
      color: white;
    }
    .bar__button {
      .link {
        background: white;
        .icon-arrow_button {
          &:before {
            color: $color-accent;
          }
        }
      }
    }
    .bar__icon {
      em,
      i {
        display: none;
      }
    }
    .bar__icon {
      margin: 0 0 0 12px;
      width: 50px;
      img {
        margin: 12px 0 0 0;
      }
    }
  }

  &:hover {
    box-shadow: $box-shadow-red;

    .link {
      text-decoration: none;
      background: $color-accent-dark;
    }

    .bar__icon {
      em {
        color: $color-accent-dark;
      }

      i {
        &:before {
          color: $color-accent-dark;
        }
      }
    }
  }
}

// Affiliate program

.affiliate-content {
  display: flex;
  flex-wrap: wrap;

  @include media-breakpoint-up(md) {
    width: 120%;
  }

  .affiliate-text {
    margin: 0 0 35px 0;

    @include media-breakpoint-down(xs) {
      flex-basis: 80%;
      margin: 0 0 25px 0;
    }

    p {
      text-align: left;
      max-width: 230px;
      word-wrap: break-word;
      margin: 0;

      @include media-breakpoint-down(xs) {
        margin: 0;
      }
    }

    &:nth-child(1) {
      @include media-breakpoint-down(xs) {
        order: 1;
      }
    }

    &:nth-child(2) {
      margin: 0 0 0 20px;

      @include media-breakpoint-down(xs) {
        margin: 15px 0;
        order: 3;
      }
    }
  }

  .bar-codes {
    border-radius: 35px;
    min-width: 230px;
    max-height: 60px;
    overflow: visible;
    cursor: pointer;

    @include media-breakpoint-down(xs) {
      order: 2;
      margin: 0 0 15px 0;
    }

    &:hover {
      background: whitesmoke;
    }

    &:last-of-type {
      margin: 0 0 0 20px;

      @include media-breakpoint-down(xs) {
        margin: 0;
        order: 4;
      }
    }

    .bar__icon {
      margin: 12px 0 0 0;

      .icon {
        margin: 0 0 0 10px;
      }
    }

    .bar__text {
      margin: 15px 0 0 0;
      min-width: 100px;
      letter-spacing: 0;

      span:first-of-type {
        font-size: 18px;
        margin: 0 0 2px 0;
      }

      span:last-of-type {
        text-align: start;
        font-size: 10px;
        font-weight: bold;
        color: #e8308a;
      }
    }

    .tooltip-text {
      margin-left: 5px;
      font-size: 12px;
      margin-bottom: -15px;
      visibility: visible;
      width: 140px;
      background-color: #555;
      color: #fff;
      text-align: center;
      border-radius: 6px;
      padding: 5px;
      position: absolute;
      z-index: 1;
      bottom: 150%;
      left: 50%;
      opacity: 1;
      transition: opacity 0.3s;
      display: none;
    }

    .tooltip-text::after {
      content: "";
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: #555 transparent transparent transparent;
    }
  }
}

.banner__content__text + .col-lg-6 {
  @include media-breakpoint-down(xs) {
    order: 3;
    margin: 30px 0 0 0;
  }
}
