.text-section{
  margin-top: -236px;
  margin-bottom: 180px;
  position: relative;
  z-index: 3;
  @include media-breakpoint-down(md) {
    margin: 0 0 150px;
  }
  @include media-breakpoint-down(xs) {
    margin: 0 0 50px;
  }
  &__wrapper{
    background-color: #fff;
    border-radius: 41px;
    padding: 68px 58px 0;
    @include media-breakpoint-down(md) {
      padding: 68px 0 0;
    }
    @include media-breakpoint-down(sm) {
      padding-top: 50px;
    }
  }
  h1{
    text-align: center;
    margin-bottom: 68px;
    @include media-breakpoint-down(sm) {
      margin-bottom: 48px;
    }
  }
  ul{
    padding-left: 15px;
  }
}