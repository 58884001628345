.sidebar-wrapper {
  position: fixed;
  right: -4000px;
  top: 25%;
  z-index: 9;
}
.sidebar {
  display: block;
  position: relative;
  color: $color-white;
  background-color: $color-accent;
  z-index: 100;
  transition: all 0.4s ease-in-out;
  padding: 47px 47px 39px 77px;
  border-radius: 41px 0 0 41px;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 138, 208, 0.3411764705882353);
  width: 4000px;
  right: 50px;
  margin-bottom: 0;
  cursor: pointer;
  @include media-breakpoint-down(md) {
    border-radius: 21px 0 0 21px;
    padding: 30px 28px 28px 68px;
    right: 30px;
  }
  &[data-sidebar="promotions"] {
    background: $color-accent url(/static/images/sidebar-bg-promotions.png)
      repeat-y 0 0;
    padding-bottom: 70px;
    padding-top: 70px;

    @include media-breakpoint-down(md) {
      background: $color-accent
        url(/static/images/sidebar-bg-promotions-mobile.png) repeat-y 0 0;
      padding-bottom: 47px;
      padding-top: 48px;
    }
    .purchase-page & {
      display: none;
    }
    // .sidebar__content {
    //   width: 375px;
    //   p {
    //     max-width: 100%;
    //   }
    // }
    // .sidebar-checkbox:checked + &,
    // &.opened {
    //   right: 425px;
    // }
  }
  &[data-sidebar="hoper-mile"] {
    background: $color-accent url(/static/images/sidebar-bg.png) repeat-y 0 0;
    @include media-breakpoint-down(md) {
      background: $color-accent url(/static/images/sidebar-bg-mobile.png)
        repeat-y 0 0;
    }
  }
  &__opener {
    // background-color: $color-sidebar;
    font-size: 16px;
    line-height: 21px;
    align-items: center;
    text-align: center;
    position: absolute;
    max-width: 285px;
    transform: rotate(-90deg);
    left: -114px;
    bottom: 102px;
    padding: 16px 30px;
    height: 50px;
    font-family: "Gilroy-Bold";
    width: 100%;
    [data-sidebar="promotions"] & {
      // background-color: #fec363;
      bottom: 100px;
      color: $color-sidebar;
      @include media-breakpoint-down(md) {
        bottom: 80px;
      }
    }
    @include media-breakpoint-down(md) {
      height: 36px;
      max-width: 205px;
      padding: 4px 0;
      text-align: center;
      font-size: 12px;
      line-height: 16px;
      height: auto;
      left: -85px;
      bottom: 82px;
    }
    svg {
      margin-right: 7px;
      transform: rotate(90deg);
      @include media-breakpoint-down(md) {
        max-width: 12px;
        max-height: 12px;
        margin-right: 5px;
      }
    }
    .a {
      fill: #fff;
    }
  }
  &__content {
    width: 250px;
  }
  p {
    max-width: 220px;
    font-family: "Gilroy-ExtraBold";
    font-size: 16px;
    line-height: 24px;
    margin: 18px 0 25px;
    @include media-breakpoint-down(md) {
      margin: 13px 0;
      font-size: 14px;
      line-height: 23px;
    }
  }
  img {
    @include media-breakpoint-down(md) {
      max-width: 149px;
    }
  }
  .btn {
    min-width: 110px;
    margin-right: 14px;
    &:last-of-type {
      margin-right: 0;
    }
    padding: 12px 55px 12px 32px;
  }
  .icon {
    img {
      height: 9px;
      left: 5px;
      position: relative;
      transform: rotate(-90deg);
    }
  }
  .sidebar-checkbox:checked + & {
    .icon {
      img {
        transform: rotate(90deg);
      }
    }
    right: 300px;
    @include media-breakpoint-down(md) {
      right: 300px;
    }
  }
}
.sidebar-checkbox {
  display: none;
}
