.news-details{
  position: relative;
  z-index: 3;
  background: linear-gradient(to bottom, transparent 0%, #e9f4fe 100%);
  padding-top: 18px;
  padding-bottom: 85px;
  @include media-breakpoint-down(sm) {
    padding-bottom: 55px;
  }
  &__wrapper{
    border-radius: 41px;
    background: linear-gradient(to bottom, white 0%, #e9f4fe 100%);
  }
  &__content{
    max-width: 770px;
    margin: 0 auto;
    padding: 59px 40px;
    @include media-breakpoint-down(md) {
      padding: 52px 0;
    }
    @include media-breakpoint-down(sm) {
      padding: 32px 0;
    }
    h1{
      font-size: 35px;
      line-height: 35px;
      margin-bottom: .5rem;
      @include media-breakpoint-down(sm) {
        font-size: 30px;
        line-height: 30px;
      }
    }
    .date{
      font-size: 14px;
      line-height: 17px;
      color: #A2A7A9;
      display: block;
      margin: 18px 0 15px;
      @include media-breakpoint-down(sm) {
        margin: 12px 0 15px;
      }
    }
    &__image{
      height: 366px;
      width: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      border-radius: 25px;
      margin-bottom: 46px;
      @include media-breakpoint-down(md) {
        height: 315px;
      }
      @include media-breakpoint-down(sm) {
        height: 220px;
        margin-bottom: 25px;
      }
    }
    &__tags {
      &__tag {
        color: #fff;
        background: #0ebcef;
        border-radius: 20px;
        display: inline-block;
        font-size: 13px;
        line-height: (15px / 13px) * 1em;
        margin: 0 0 10px 0;
        padding: 4px 6px;
        &:hover {
          background: darken(#0ebcef, 10%);
          color: #fff;
          text-decoration: none;
        }
      }
    }
    &__text{
      line-height: 21px;
      p{
        margin-bottom: 21px;
        @include media-breakpoint-down(sm) {
          margin-bottom: 10px;
        }
      }
    }
    &__actions{
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-wrap: wrap;
      margin-top: 42px;
      @include media-breakpoint-down(sm) {
        margin-top: 20px;
      }
      @include media-breakpoint-down(xs) {
        display: block;
      }
      .btn{
        margin-top: 10px;
        @include media-breakpoint-down(xs) {
          margin: 20px auto 0;
          display: block;
          min-width: 200px;
          max-width: 200px;
        }
      }
    }
  }
}