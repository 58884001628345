.how-it-works {
  margin-top: -209px;
  text-align: center;
  @include media-breakpoint-down(sm) {
    margin-top: -70px;
  }
  .row {
    &.steps {
      display: flex;
      @for $i from 1 through 8 {
        &[data-steps-count="#{$i}"] > [class*="col"] {
          flex: 0 0 (100% / $i);
          max-width: (100% / $i);
        }
      }
      & > [class*="col"] {
        @include media-breakpoint-down(md) {
          flex: 0 0 100% !important;
          max-width: 100% !important;
        }
        @include media-breakpoint-down(xs) {
          flex: 0 0 100% !important;
          max-width: 100% !important;
        }
      }
    }
  }
  &__step {
    @include media-breakpoint-down(md) {
      margin-top: 20px;
    }
    @include media-breakpoint-down(xs) {
      margin-top: 10px;
    }
    &:not([data-manual-image="true"]) {
      &[data-id="1"] {
        .image {
          background: transparent url("/static/images/how-it-works/1.svg")
            center bottom no-repeat;
        }
        a {
          &:hover {
            .image {
              background-image: url("/static/images/how-it-works/1-hover.svg");
            }
          }
        }
      }
      &[data-id="2"] {
        .image {
          background: transparent url("/static/images/how-it-works/2.svg")
            center bottom no-repeat;
        }
        a {
          &:hover {
            .image {
              background-image: url("/static/images/how-it-works/2-hover.svg");
            }
          }
        }
      }
      &[data-id="3"] {
        .image {
          background: transparent url("/static/images/how-it-works/3.svg")
            center bottom no-repeat;
        }
        a {
          &:hover {
            .image {
              background-image: url("/static/images/how-it-works/3-hover.svg");
            }
          }
        }
      }
      &[data-id="4"] {
        .image {
          background: transparent url("/static/images/how-it-works/4.svg")
            center bottom no-repeat;
        }
        a {
          &:hover {
            .image {
              background-image: url("/static/images/how-it-works/4-hover.svg");
            }
          }
        }
      }
      &[data-id="5"] {
        .image {
          background: transparent url("/static/images/how-it-works/5.svg")
            center bottom no-repeat;
        }
        a {
          &:hover {
            .image {
              background-image: url("/static/images/how-it-works/5-hover.svg");
            }
          }
        }
        &.en {
          .image {
            background-image: url("/static/images/how-it-works/5en.svg");
          }
          a {
            &:hover {
              .image {
                background-image: url("/static/images/how-it-works/5en-hover.svg");
              }
            }
          }
        }
      }
    }
    .image {
      background-size: contain !important;
      height: 157px;
      margin-bottom: 21px;
      @include media-breakpoint-down(md) {
        height: 125px;
      }
      img {
        max-height: 100%;
      }
    }
    a {
      h4 {
        color: $color-text;
      }
      &:hover {
        text-decoration: none;
        h4 {
          color: $color-accent;
          text-decoration: none;
        }
        .arrow-link {
          background-image: url("/static/images/arrow-blue-red.svg");
        }
        p {
          color: $color-text;
        }
      }
      p {
        color: $color-text;
      }
    }
    h4 {
      color: $color-text;
      min-height: 50px;
      margin-bottom: 20px;
      padding: 0 15px;
      @include media-breakpoint-down(md) {
        margin-bottom: 10px;
      }
      @include media-breakpoint-down(sm) {
        min-height: auto;
      }
    }
    .arrow-link {
      display: inline-block;
      margin-left: 10px;
      height: 15px;
      width: 15px;
      background: transparent url("/static/images/arrow-blue-right.svg") 0 2px
        no-repeat;
      @include media-breakpoint-down(sm) {
        display: block;
        margin: 5px auto 0;
        text-align: center;
      }
    }
  }
  &__video {
    text-align: left;
    padding-top: 70px;
    padding-bottom: 26px;
    @include media-breakpoint-down(md) {
      padding-top: 30px;
    }
    .text {
      padding: 48px 135px 0 0;
      @include media-breakpoint-down(lg) {
        padding: 0 0 30px 0;
      }
      h3 {
        margin-bottom: 35px;
        @include media-breakpoint-down(sm) {
          margin-bottom: 20px;
        }
      }
    }
    .video {
      width: 100%;
      height: 318px;
      box-shadow: $box-shadow-blue;
      border-radius: 39px;
      position: relative;
      background-image: url("/static/images/img/video-row.png");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      @include media-breakpoint-down(xs) {
        height: 205px;
      }
      button {
        position: absolute;
        left: 50%;
        top: 64%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
      }
    }
  }
}
.boxes {
  .how-it-works {
    margin-top: -224px;
    @include media-breakpoint-down(sm) {
      margin-top: -70px;
    }
  }
  .how-it-works__step {
    margin-top: 41px;
    .image {
      height: 115px;
      margin-bottom: 46px;
      @include media-breakpoint-down(md) {
        height: 125px;
        margin-bottom: 21px;
      }
    }
  }
  .tile-white {
    padding: 59px 80px 45px;
    @include media-breakpoint-down(md) {
      padding: 45px 40px;
    }
    @include media-breakpoint-down(xs) {
      padding: 35px 15px;
    }
  }
}
