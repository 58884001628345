.reviews-grid{
  margin: -200px 0 0;
  text-align: center;
  position: relative;
  z-index: 3;
  background: linear-gradient(to bottom, transparent 0%, #E9F4FE 100%);
  @include media-breakpoint-down(md) {
    margin-top: -200px;
  }
  @include media-breakpoint-down(sm) {
    margin-top: -55px;
  }
  &__wrapper{
    padding: 68px 58px 80px;
    border-radius: 41px;
    background: linear-gradient(to bottom, white 0%, #E9F4FE 100%);
    @include media-breakpoint-down(md) {
      padding: 68px 25px 80px;
    }
    @include media-breakpoint-down(sm) {
      padding-top: 50px;
    }
  }
  &__content{
    margin: 55px 0 0;
    position: static;
    display: block;
    //justify-content: flex-start;
    //align-items: flex-start;
    //flex-wrap: wrap;
    column-count: 2;
    &.focused {
      column-count: 1;
    }
    @include media-breakpoint-down(md) {
      margin: 55px -15px 0;
    }
    @include media-breakpoint-down(sm) {
      column-count: unset;
    }
  }
  .reviews__item{
    height: 100%;
  }
  .reviews__item__wrapper{
    //flex: 1 1 50%;
    display: inline-block;
    @include media-breakpoint-down(sm) {
      //flex: 1 1 100%;
      display: block;
    }
  }
}