.info-banner{
  position: relative;
  padding-bottom: 80px;
  overflow: hidden;
  @include media-breakpoint-down(xs) {
    padding-bottom: 0;
  }
  &::after{
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    height: 80px;
    width: 100%;
    background-color: $color-white;
    z-index: 1;
    @include media-breakpoint-down(xs) {
      display: none;
    }
  }
  &.reversed{
    .decor{
      bottom: -145px;
      left: auto;
      right: 62%;
      width: 927px;
      height: 720px;
      @include media-breakpoint-down(xs) {
        display: none;
      }
      & > span{
        font-size: 720px;
      }
      .icon-faq_maziaj{
        display: block;
      }
      .icon-wyslij_paczke_maziaj{
        display: none;
      }
    }
    .info-banner__content{
      justify-content: flex-end;
    }
    .info-banner__content__wrapper{
      background-color: $color-red;
    }
    .info-banner__image{
      order: 2;
      @include media-breakpoint-down(sm) {
        order: 1;
      }
    }
    .info-banner__content__description{
      order: 1;
      margin: 38px 154px 30px 0;
      max-width: 316px;
      @include media-breakpoint-down(lg) {
        max-width: 360px;
        margin: 25px 75px 20px 0;
      }
      @include media-breakpoint-down(md) {
        max-width: 545px;
        margin: 25px 40px 20px 0;
      }
    }
  }
  .decor{
    position: absolute;
    bottom: -150px;
    left: 50%;
    width: 1490px;
    height: 960px;
    @include media-breakpoint-down(xs) {
      display: none;
    }
    & > span{
      font-size: 960px;
    }
    .icon-faq_maziaj{
      display: none;

      width: 927px;
      height: 720px;
      position: relative;
      .path1{
        position: absolute;
        left: 0;
        top: 0;
        &::before{
          position: relative;
          z-index: 2;
        }
      }
      .path2{
        position: absolute;
        left: 0;
        top: 0;
        &::before{
          margin-left: 0;
        }
      }
      .path3{
        &::before{
          position: relative;
          z-index: 2;
          margin-left: 0;
        }
      }
    }
    .icon-wyslij_paczke_maziaj{
      .path2, .path4{
        &::before{
          position: relative;
          z-index: 2;
        }
      }
    }
  }
  &__image{
    img{
      border-radius: 41px;
    }
  }
  &__content{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    z-index: 3;
    @include media-breakpoint-down(sm) {
      flex-wrap: wrap;
    }
    &__wrapper{
      background-color: $color-blue;
      padding: 40px 0 37px;
    }
    &__description{
      color: $color-white;
      margin: 48px 0 30px 133px;
      max-width: 315px;
      @include media-breakpoint-down(md) {
        margin: 5px 0 20px 60px;
      }
      @include media-breakpoint-down(sm) {
        margin: 30px 0 20px;
        max-width: 380px;
      }
      h2{
        line-height: 40px;
        margin-bottom: 36px;
        @include media-breakpoint-down(md) {
          margin-bottom: 20px;
        }
        @include media-breakpoint-down(sm) {
          line-height: 30px;
        }
      }
      .btn{
        margin-top: 27px;
        @include media-breakpoint-down(md) {
          margin-top: 10px;
        }
      }
      p {
        a {
          color: white;
          font-family: 'Gilroy-ExtraBold';

        }
      }
    }
  }
}

.boxes{
  .info-banner{
    margin-top: 115px;
    @include media-breakpoint-down(sm) {
      margin-top: 60px;
    }
  }
}

.contact{
  .info-banner{
    &.reversed{
      .info-banner__content{
        justify-content: space-between;
      }
      .info-banner__content__description{
        max-width: 460px;
      }
    }
  }
}