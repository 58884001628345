.recommend-banner.info-banner.reversed {
  padding: 150px 0 80px 0;

  @include media-breakpoint-down(xs) {
    padding: 60px 0 0;
  }

  .decor {
    right: 58%;
    top: 100px;
    bottom: 0;

    .icon-faq_maziaj {
      font-size: 460px;
      font-weight: bolder;

    }
  }

  .info-banner__content__description {
    max-width: 380px;
    margin: 38px 120px 30px 0;

    @include media-breakpoint-down(lg) {
      max-width: 360px;
      margin: 25px 75px 20px 0;
    }
    @include media-breakpoint-down(md) {
      max-width: 545px;
      margin: 25px 40px 20px 0;
    }
  }

  .info-banner__content__wrapper {
    padding: 50px 0 47px;
  }
}