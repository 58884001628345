.mile-discount {
  padding: 80px 0 19px 0;
  position: relative;
  overflow: hidden;
  @include media-breakpoint-down(md) {
    padding: 0;
  }

  .btn-secondary {
    min-width: 105px;
    padding: 8px 20px 8px 15px;
    margin-top: 10px;
    display: none;
    opacity: 0;
    transition: opacity .4s ease-in-out, color .2s ease-in-out;
    @include media-breakpoint-down(md) {
      margin-top: 5px;
    }
    .icon {
      background-color: $color-accent-dark;
    }
  }

  &:before {
    content: '';
    left: 0;
    position: absolute;
    height: 80px;
    right: 20%;
    background: $color-white;
    top: 0;
    z-index: 1;
    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  &__content {
    background: $color-blue;
    padding: 77px 0 220px 0;
    @include media-breakpoint-down(md) {
      padding: 60px 0 100px;
    }
  }

  &__percent-line {
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 3;
    max-width: 1100px;
    margin: 0 auto;
    @include media-breakpoint-down(md) {
      flex-direction: column;
      width: 300px;
    }

    &:before {
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 7px 0 7px 14px;
      border-color: transparent transparent transparent $color-blue;
      position: absolute;
      top: 50%;
      left: -45px;
      transform: translateY(-50%);
      @include media-breakpoint-down(lg) {
        left: -25px;
      }
      @include media-breakpoint-down(md) {
        left: 22.5px;
        top: -18px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 14px 10px 0 9px;
        border-color: #1796D5 transparent transparent transparent;
      }
    }

    &:after {
      content: '';
      position: absolute;
      height: 17px;
      background: $color-white;
      top: 50%;
      left: -45px;
      right: 40px;
      transform: translateY(-50%);
      z-index: -1;
      @include media-breakpoint-down(lg) {
        left: -25px;
      }
      @include media-breakpoint-down(md) {
        left: 24px;
        width: 17px;
        right: auto;
        -webkit-transform: none;
        transform: none;
        top: -25px;
        bottom: 80px;
        height: auto;
        opacity: .4;
        box-shadow: 0 5px 10px rgba(0,0,0,0.1607843137254902);
      }
    }
  }

  &__circle {
    background: $color-white;
    width: 74px;
    height: 74px;
    border-radius: 50%;
    box-shadow: 8px 8px 5px rgba(0,0,0,0.14902);
    display: flex;
    justify-content: center;
    align-items: center;
    color: $color-primary;
    font-family: 'Gilroy-ExtraBold';
    font-size: 20px;
    line-height: 20px;
    cursor: pointer;
    position: relative;
    transition: background .4s ease-in-out, color .2s ease-in-out;
    text-align: center;
    padding: 0 10px;
    &:after {
      content: '';
      display: block;
      border-radius: 50%;
      width: 103px;
      height: 103px;
      background-color: $color-primary-dark;
      opacity: 0.22;
      left: 50%;
      top: 50%;
      position: absolute;
      transform: translate(-50%, -50%);
      z-index: -1;
    }

    &:hover {
      background: $color-discount-hover;
      color: $color-white;

      & .mile-discount__text {
        background-color: $color-discount-hover;
        color: $color-white;

        &--up {
          &:before {
            border-color: $color-discount-hover transparent transparent transparent;
            @include media-breakpoint-down(md){
            border-color: transparent $color-discount-hover transparent transparent;
              }
          }
        }

        &--down {
          &:before {
            border-color: transparent transparent $color-discount-hover transparent;
            @include media-breakpoint-down(md){
            border-color: transparent $color-discount-hover transparent transparent;
              }
          }
        }

        p {
          margin-top: 0;
        }

        .btn-secondary {
          //display: inline-block;
          opacity: 1;
        }
      }

      &:after {
        background-color: $color-timeline-circles-hover;
        opacity: 0.07;
      }
    }
  }

  &__text {
    position: absolute;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1607843137254902);
    background: $color-white;
    border-radius: 10px;
    padding: 10px 17px;
    font-family: 'Gilroy-ExtraBold';
    font-size: 18px;
    line-height: 20px;
    color: $color-primary;
    text-align: center;
    min-height: 123px;
    min-width: 139px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color .4s ease-in-out, color .2s ease-in-out;
    @include media-breakpoint-down(md) {
      right: -222px;
      min-width: initial;
      min-height: initial;
      height: 103px;
      width: 171px;
    }

    &:before {
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      position: absolute;
      transform: translateX(-50%);
      left: 50%;
      transition: border-color .4s ease-in-out, color .2s ease-in-out;
    }

    &--up {
      top: -166px;
      @include media-breakpoint-down(md) {
        top: auto;
      }

      &:before {
        border-width: 20px 22px 0 22px;
        bottom: -20px;
        border-color: #ffffff transparent transparent transparent;
        @include media-breakpoint-down(md) {
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 10.5px 16px 10.5px 0;
          border-color: transparent $color-white transparent transparent;
          top: 50%;
          bottom: auto;
          transform: translateY(-50%);
          left: -16px;
        }
      }
    }

    &--down {
      bottom: -175px;
      @include media-breakpoint-down(md) {
        bottom: auto;
      }

      &:before {
        border-width: 0 22px 20px 22px;
        top: -20px;
        border-color: transparent transparent #ffffff transparent;
        @include media-breakpoint-down(md) {
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 10.5px 16px 10.5px 0;
          border-color: transparent $color-white transparent transparent;
          top: 50%;
          bottom: auto;
          transform: translateY(-50%);
          left: -16px;
        }
      }
    }

    p {
      margin: 0;
      transition: margin-top .2s linear;
    }
  }

  &__tip {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    min-height: 230px;
    width: 143px;
    z-index: 2;
    @include media-breakpoint-down(md) {
      min-height: 105px;
      justify-content: flex-start;
      width: 100%;
      margin-bottom: 50px;
    }
  }

  &__triangle-cont {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 19px;
    background: $color-white;
    img{
      position: absolute;
      left: 50%;
      transform: translate(-50%, -13px);
    }
  }

  h2 {
    text-align: center;
    color: $color-white;
    margin: 0 0 205px;
    @include media-breakpoint-down(lg) {
      margin: 0 0 120px;
    }
    @include media-breakpoint-down(md) {
      margin: 0 0 60px;
    }
  }

  [class^="icon-"], [class*=" icon-"] {
    font-weight: bold;
  }

  .left-deco {
    position: absolute;
    overflow: hidden;
    font-size: 710px;
    right: 81%;
    bottom: -70px;
    transform: rotate(-3deg);
    @include media-breakpoint-down(lg) {
      font-size: 480px;
    }
    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  .right-deco {
    font-size: 758px;
    position: absolute;
    left: 70%;
    top: -159px;
    transform: rotate(-22deg);
    @include media-breakpoint-down(lg) {
      font-size: 575px;
      top: -110px;
    }
    @include media-breakpoint-down(md) {
      display: none;
    }
  }
}