.mile-steps {
  margin-top: 123px;
  padding-bottom: 81px;
  @include media-breakpoint-down(md){
    margin-top: 70px;
  }
  &__content {
    padding-right: 115px;
    @include media-breakpoint-down(lg){
      padding-right: 70px
    }
    @include media-breakpoint-down(md){
      padding-right: 0;
      padding-left: 85px
    }
    div {
      max-width: 360px;
      margin-left: auto;
      position: relative;
      margin-top: 76px;
      &:before{
        content: '';
        position: absolute;
        top: 55px;
        bottom: -40px;
        left: -30px;
        border: 1px solid $color-gray-semi;
        @include media-breakpoint-down(md){
          bottom: -15px;
        }
      }
      &:last-of-type{
        &:before{
          display: none;
        }
      }
      @include media-breakpoint-down(lg){
        padding-right: 30px;
      }
      @include media-breakpoint-down(md){
        width: 100%;
        max-width: initial;
        padding-right: 0;
        margin-top: 46px;
      }
      &:first-child{
        margin-top: 56px;
        @include media-breakpoint-down(lg){
          margin-top: 30px;
        }
        @include media-breakpoint-down(md){
          margin-top: inherit;
        }
      }
    }
  }
  &__image{
    box-shadow: 10px 10px 40px rgba(0,141,208,0.14901960784313725);
    border-radius: 40px;
    background-size: cover;
    height: 625px;
    width: 470px;
    margin-right: 35px;
    @include media-breakpoint-down(lg){
      margin-right: 0;
      width: 400px;
      height: 560px;
    }
    @include media-breakpoint-down(md){
      width: 100%;
      height: 460px;
      order: 2;
      margin: 25px 15px 0 15px;
      background-position: center;
    }
    @include media-breakpoint-down(xs){
      width: 100%;
      height: 210px;
      order: 2;
      background-position: center 25%;
    }
  }
  .row{
    justify-content: flex-end;
    @include media-breakpoint-down(lg){
      justify-content: space-between;
    }
  }
  h2 {
    text-align: center;
    margin: 0 0 83px 0;
    @include media-breakpoint-down(md){
      margin: 0 0 69px 0;
    }
  }
  svg{
    position: absolute;
    top: -10px;
    left: -69px;
    z-index: -1;
  }
  @include media-breakpoint-down(md){
    left: -75px;
  }
}