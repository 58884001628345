.icons-grid {
  position: relative;
  text-align: center;
  margin-top: -102px;
  @include media-breakpoint-down(lg) {
    margin-top: -110px;
  }
  @include media-breakpoint-down(md) {
    margin-top: -65px;
  }
  @include media-breakpoint-down(sm) {
    margin-top: -100px;
  }
  .tile-white {
    padding: 57px 62px 46px 62px;
    position: relative;
    z-index: 1;
    @include media-breakpoint-down(md) {
      padding: 45px 40px;
    }
    @include media-breakpoint-down(xs) {
      padding: 35px 15px;
    }
  }
  h2 {
    margin-top: 25px;
    margin-bottom: 93px;
    @include media-breakpoint-down(md) {
      margin-bottom: 60px;
    }
  }
  .tile {
    margin-bottom: 63px;
    @include media-breakpoint-down(md) {
      margin-bottom: 40px;
    }
    @include media-breakpoint-down(sm) {
      max-width: 350px;
      margin: 0 auto 40px;
    }
    .image {
      img {
        max-height: 70px;
        margin-bottom: 35px;
        @include media-breakpoint-down(sm) {
          margin-bottom: 25px;
        }
      }
    }
  }
}

.additional-bg {
  position: absolute;
  z-index: 0;
  top: 14%;
  height:76%;
  background: $color-blue;
  width: 100%;
  left: 0;
  text-align: left;
  @include media-breakpoint-down(md) {
    top: 6%;
    height: 89%;
  }
  .left {
    font-size: 700px;
    position: absolute;
    left: -350px;
    bottom: -185px;
    width: 1000px;
    @include media-breakpoint-down(xs) {
      display: none;
    }
    &:after {
      content: "";
      width: 50%;
      height: 185px;
      background: white;
      display: block;
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
  .right {
    position: absolute;
    right: 0;
    top: 0;
    overflow: hidden;
    font-size: 800px;
    width: 1000px;
    @include media-breakpoint-down(xs) {
      display: none;
    }
    > span {
      position: relative;
      top: -300px;
      left: 250px;
    }
  }
}