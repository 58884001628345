.contact-faq{
  margin-top: -210px;
  padding: 280px 0 150px;
  background: transparent linear-gradient(0deg, #E9F4FE 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
  @include media-breakpoint-down('md'){
    padding: 240px 0 50px;
  }
  h2{
    line-height: 50px;
    max-width: 334px;
    color: $color-text;
    @include media-breakpoint-down('md'){
      line-height: 35px;
    }
    @include media-breakpoint-down('sm'){
      margin-bottom: 40px;
    }
  }
  .faq{
    margin-left: 55px;
    @include media-breakpoint-down('lg'){
      margin-left: 0;
    }
  }
  .btn{
    margin: 40px 0 0 55px;
    @include media-breakpoint-down('lg'){
      margin-left: 0;
    }
  }
}