.advantages {
  margin: 106px 0 0;
  text-align: center;
  @include media-breakpoint-down(lg) {
    margin: 80px 0 0;
  }
  &__item {
    background-color: $color-gray-semi;
    box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.16);
    border-radius: 41px;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    @include media-breakpoint-down(sm) {
      flex-wrap: wrap;
    }
    &__wrapper {
      margin-top: 70px;
      padding-top: 55px;
      @include media-breakpoint-down(sm) {
        margin-top: 60px;
      }
      & + .advantages__item__wrapper {
        margin-top: 60px;
      }
      &:nth-child(2n + 1) {
        .advantages__item__image,
        .advantages__item__image--wrapper {
          order: 1;
          @include media-breakpoint-down(sm) {
            order: 0;
          }
        }
        .advantages__item__image__extra-icon {
          left: auto;
          right: 30px;
        }
        .advantages__item__content {
          padding: 68px 89px 20px 83px;
          @include media-breakpoint-down(lg) {
            padding: 50px 60px 20px 50px;
          }
          @include media-breakpoint-down(md) {
            padding: 30px 35px 20px 40px;
          }
          @include media-breakpoint-down(xs) {
            padding: 30px 20px 20px 20px;
          }
        }
      }
    }
    &__image {
      width: 50%;
      max-width: 574px;
      min-height: 340px;
      background-repeat: no-repeat;
      background-position: center top;
      background-size: cover;
      border-radius: 41px;
      height: 100%;
      margin-top: -55px;
      position: relative;
      &--wrapper {
        margin-top: -55px;
        position: relative;
        width: 50%;
        .advantages__item__image {
          margin-top: 0;
          width: 100%;
        }
      }
      &__extra-icon {
        position: absolute;
        top: 85px;
        left: 30px;
        @include media-breakpoint-down(sm) {
          height: 82px;
          left: auto;
          right: 30px;
          top: 55px;
          width: 64px;
        }
      }
      @include media-breakpoint-down(sm) {
        &,
        &--wrapper {
          width: 100%;
          max-width: 100%;
          min-height: 250px;
        }
      }
    }
    &__content {
      max-width: 50%;
      text-align: left;
      padding: 68px 89px 20px 72px;
      @include media-breakpoint-down(lg) {
        padding: 50px 60px 20px 50px;
      }
      @include media-breakpoint-down(md) {
        padding: 30px 35px 20px 40px;
      }
      @include media-breakpoint-down(sm) {
        max-width: 100%;
      }
      @include media-breakpoint-down(xs) {
        padding: 30px 20px 20px 20px;
      }
      h3 {
        margin-bottom: 30px;
        color: $color-primary;
        @include media-breakpoint-down(sm) {
          margin-bottom: 10px;
        }
      }
    }
  }
}
