.pagination{
  padding: 30px 0 95px;
  @include media-breakpoint-down(sm) {
    padding: 20px 0 40px;
  }
  span{
    display: inline-block;
    cursor: pointer;
    font-family: 'Gilroy-ExtraBold';
  }
  .arrow{
    background-color: $color-white;
    display: inline-block;
    width: 41px;
    height: 41px;
    border-radius: 50%;
    border: none;
    transition: $transition-base;
    &:hover{
      background-color: $color-accent;
      span, a{
        &::before{
          color: $color-white;
        }
      }
    }
    &.previous--page{
      span, a{
        &::before{
          transform: rotate(-180deg);
        }
      }
    }
    &.disable{
      opacity: .6;
      pointer-events: none;
    }
    span, a{
      font-size: 14px;
      line-height: 41px;
      &::before{
        transition: $transition-base;
        color: $color-accent;
        display: inline-block;
      }
    }
  }
  .page--number{
    color: $color-text;
    transition: $transition-base;
    margin: 0 20px;
    font-family: 'Gilroy-ExtraBold';
    font-size: 18px;
    line-height: 27px;
    text-decoration: none;
    &:hover{
      color: $color-accent;
    }
    &.active{
      color: $color-accent;
    }
  }
}