.modal {
  background: rgba(0, 0, 0, 0.7);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 999;
  &__inner {
    background: #fff;
    left: 50%;
    padding: 30px;
    position: absolute;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 440px;
    max-width: 90%;
    &__title {
      position: relative;
      padding-right: 34px;
      &__content {
        font-family: "Gilroy-Bold";
        font-size: 17px;
        font-weight: normal;
        line-height: (24px/17px) * 1em;
      }
      &__close {
        border: 1px solid $color-text;
        border-radius: 100%;
        color: $color-text;
        cursor: pointer;
        font-size: 26px;
        font-weight: 300;
        height: 20px;
        position: absolute;
        transform: rotate(45deg);
        transition: $transition-base;
        right: 0;
        top: 0;
        text-align: center;
        width: 20px;
        &:hover {
          border: 1px solid darken($color-text, 10%);
          color: darken($color-text, 10%);
          text-decoration: none;
        }
      }
    }
    &__content {
      border-top: 1px solid #707070;
      font-family: "Gilroy-Light";
      font-size: 14px;
      line-height: 20px/14px;
      margin: 18px 0 0;
      padding: 18px 0;
    }
    &__footer {
      text-align: right;
    }
  }
}
