.mile-benefits{
  margin: -470px 0 80px 0;

  @include media-breakpoint-down(md){
    margin: 30px 0 40px;
  }
  &__content{
    display: flex;
    justify-content: space-between;
    padding: 0 0 0 68px;
    @include media-breakpoint-down(lg){
      padding: 0;
    }
    @include media-breakpoint-down(md){
      flex-wrap: wrap;
    }
  }
  &__items{
    max-width: 330px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @include media-breakpoint-down(md){
      width: 100%;
      max-width: initial;
    }
  }
  &__item{
    width: 100%;
    margin-top: 20px;
    @include media-breakpoint-down(lg){
      margin-top: 0;
    }
    @include media-breakpoint-down(md){
      margin-top: 55px;
    }
  }
  &__image{
    width: 500px;
    height: 769px;
    border-radius: 10px;
    background-size: cover;
    @include media-breakpoint-down(lg){
      width: 380px;
      height: 650px;
    }
    @include media-breakpoint-down(md){
      width: 100%;
      margin-top: 40px;
    }
    @include media-breakpoint-down(xs){
      width: 100%;
      height: 390px;
    }
  }
  h2{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 58px 0;
    @include media-breakpoint-down(md){
      text-align: center;
      display: block;
      margin: 0 0 20px 0;
    }
    @include media-breakpoint-down(xs){
        max-width: 300px;
      margin: 0 auto 20px;
    }
    img{
      @include media-breakpoint-down(md){
        display: inline-block;
        margin-top: 15px;
      }
      @include media-breakpoint-down(md){
        margin-top: 0;
      }
    }
  }
  h4{
    color: $color-primary;
    font-size: 20px;
    line-height: 24px;
    margin: 8px 0 10px 0;
  }
  .tile-white{
    padding: 54px 100px 97px 54px;
    @include media-breakpoint-down(md){
      padding: 45px 40px;
    }
    @include media-breakpoint-down(xs){
      padding: 35px 15px;
    }
    img{
      margin-left: 10px;
      @include media-breakpoint-down(md){
        margin-top: 10px;
      }
      @include media-breakpoint-down(xs){
        margin-top: 5px;
      }
    }
  }
}