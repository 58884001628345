.ticket-buy {
  background: $white;
  border-radius: 41px;
  box-shadow: 5px 5px 20px 0 rgba(0, 138, 207, 0.3);
  margin: -245px auto 155px;
  max-width: 1185px;
  padding: 84px 0 198px;
  position: relative;
  width: 100%;
  z-index: 1;
  @include media-breakpoint-down(sm) {
    box-shadow: none;
    margin: 0;
    padding-bottom: 0;
  }
  &__inner {
    background: transparent
      url("/static/images/ticket-buy-explainer/buy-ticket-explainer-main-bg-min.png")
      36px 95px no-repeat;
    margin: 0 auto;
    // max-width: 926px;
    max-width: 734px;
    padding: 152px 0 0 96px;
    width: 100%;
    @include media-breakpoint-down(sm) {
      background-image: none;
      padding: 0 70px 0;
    }
  }
  &__header {
    font-size: 35px;
    line-height: 1em;
    text-align: center;
    @include media-breakpoint-down(sm) {
      font-size: 30px;
      line-height: 35px;
    }
  }
  &__row {
    align-items: center;
    display: flex;
    padding-bottom: 159px;
    position: relative;
    @include media-breakpoint-down(sm) {
      flex-direction: column;
      padding: 45px 0;
      &:nth-of-type(odd) {
        background: transparent
          url("/static/images/ticket-buy-explainer/arrow-left-min.png") center
          bottom no-repeat;
      }
      &:nth-of-type(even) {
        background: transparent
          url("/static/images/ticket-buy-explainer/arrow-right-min.png") center
          bottom no-repeat;
      }
      &:last-of-type {
        background-image: none;
        padding-bottom: 90px;
      }
    }
    &__number {
      color: #e7f2fb;
      left: -92px;
      height: 81px;
      position: absolute;
      top: -37px;
      z-index: -1;
      width: 132px;
      &[src$="step_count_1.svg"] {
        width: 111px;
      }
      &[src$="step_count_7.svg"] {
        width: 126px;
      }
      @include media-breakpoint-down(sm) {
        left: -30px;
        top: 0px;
        height: 81px;
        width: 132px;
        &[src$="step_count_1.svg"] {
          height: 94px;
          width: 128px;
        }
        &[src$="step_count_4.svg"] {
          width: 132px;
          height: 74px;
        }
        &[src$="step_count_5.svg"] {
          width: 132px;
          height: 77px;
        }
      }
    }
    &__img {
      width: auto;
      height: 190px;
      @include media-breakpoint-down(sm) {
        height: auto;
        max-width: 260px;
        max-height: 212px;
        width: 100%;
      }
    }
    &__description {
      color: #707070;
      font-size: 18px;
      letter-spacing: 0px;
      line-height: (23px/18px) * 1em;
      padding: 0 0 0 74px;
      width: 407px;
      @include media-breakpoint-down(sm) {
        width: 100%;
        padding: 35px 0 0;
      }
    }
  }
}
