.btn{
  display: inline-block;
  vertical-align: middle;
  transform: perspective(1px) translateZ(0);
  transition: $transition-base;
  font-family: 'Gilroy-ExtraBold';
  font-size: 12px;
  line-height: 17px;
  padding: 12px 55px 12px 21px;
  min-width: 157px;
  cursor: pointer;
  border: none;
  border-radius: 25px;
  text-align: left;
  position: relative;
  &.btn-primary{
    background: $color-white;
    color: $color-accent-dark;
    &:hover, &:active, &:focus{
      background: $color-white;
      color: $color-accent-dark;
    }
    .icon{
      background-color: $color-gray-semi;
      & > *{
        &::before{
          color: $color-accent;
        }
      }
    }
  }
  &.btn-secondary{
    background: $color-accent-dark;
    color: $color-white;
    &:hover, &:active, &:focus{
      background: $color-accent-dark;
      color: $color-white;
    }
    .icon{
      background-color: $color-accent;
      & > *{
        &::before{
          color: $color-white;
        }
      }
    }
  }
  &.btn-revert{
    padding: 14px 25px 11px 57px;
    .icon{
      right: auto;
      left: 0;
      & > i, & > span{
        top: 50%;
        right: auto;
        left: 13px;
        &::before{
          transform: rotate(180deg);
          display: block;
        }
      }
    }
  }
  &.btn-phone{
    font-size: 16px;
  }
  &:hover, &:active, &:focus{
    border: none;
    box-shadow: $box-shadow-red;
    .icon{
      width: 100%;
    }
  }
  &.play {
    background: white;
    height: 67px;
    min-width: 219px;
    font-size: 18px;
    padding: 23px 22px;
    @include media-breakpoint-down(xs) {
      height: 55px;
      min-width: 185px;
      font-size: 15px;
    }
    i {
      background: $color-accent-dark;
      width: 41px;
      height: 41px;
      border-radius: 41px;
      position: absolute;
      top: 13px;
      right: 21px;
      font-size: 14px;
      padding: 14px 12px;
      transform: rotate(-90deg);
      @include media-breakpoint-down(xs) {
        width: 30px;
        height: 30px;
        border-radius: 30px;
        right: 26px;
        font-size: 10px;
        padding: 11px 9px;
      }
      &:before {
        color: white;
      }
    }
  }
  .icon{
    width: 41px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    border-radius: 25px;
    transition: $transition-base;
    z-index: -1;
    & > i, & > span{
      font-size: 14px;
      position: absolute;
      top: 50%;
      right: 13px;
      transform: translateY(-50%);
    }
  }
}