.faq {
  text-align: center;
  margin-top: -236px;
  margin-bottom: 100px;
  position: relative;
  z-index: 3;
  @include media-breakpoint-down(md) {
    margin-bottom: 60px;
    margin-top: 0;
  }
  &__wrapper {
    background-color: $color-white;
    border-radius: 41px;
    padding-top: 71px;
    @include media-breakpoint-down(sm) {
      padding-top: 50px;
    }
    h1 {
      font-size: 35px;
      line-height: 35px;
      margin-bottom: 0.5rem;
      @include media-breakpoint-down(sm) {
        font-size: 30px;
        line-height: 30px;
      }
    }
  }
  &__category {
    max-width: 770px;
    margin: 66px auto 0;
    @include media-breakpoint-down(sm) {
      margin: 40px auto 0;
    }
    & > h3 {
      color: $color-primary;
      margin-bottom: 40px;
    }
  }
  &__item {
    box-shadow: 5px 5px 20px 0 rgba(0, 138, 207, 0.3);
    border-radius: 19px;
    text-align: left;
    position: relative;
    padding: 28px 26px 17px;
    cursor: pointer;
    @include media-breakpoint-down(sm) {
      padding: 20px 15px 10px;
    }
    &:hover {
      .faq__item__title {
        color: $color-accent;
      }
    }
    & + .faq__item {
      margin-top: 29px;
      @include media-breakpoint-down(sm) {
        margin-top: 20px;
      }
    }
    &.expanded {
      .actions {
        transform: rotate(45deg);
      }
    }
    .actions {
      position: absolute;
      right: 24px;
      top: 30px;
      color: $color-accent;
      font-size: 45px;
      transition: $transition-base;
      @include media-breakpoint-down(sm) {
        right: 15px;
        top: 22px;
      }
    }
    &__title {
      margin-right: 35px;
      letter-spacing: -0.45px;
      transition: $transition-base;
    }
    &__text {
      display: none;
      font-size: 14px;
      line-height: 20px;
      margin: 30px 0 25px;
      position: relative;
      cursor: default;
      @include media-breakpoint-down(sm) {
        margin: 15px 0 15px;
      }
      p {
        margin-bottom: 20px;
        @include media-breakpoint-down(sm) {
          margin-bottom: 10px;
        }
      }
    }
  }
  &__search {
    border: 1px solid #cccccc;
    border-radius: 35px;
    height: 55px;
    margin: 36px auto 0;
    max-width: 487px;
    padding: 18px 32px;
    position: relative;
    &__input {
      border: none;
      height: 19px;
      font-size: 16px;
      line-height: 19px;
      width: 100%;
    }
    &__icon {
      &--search {
        cursor: pointer;
        position: absolute;
        right: 26px;
        top: 18px;
      }
      &--clear {
        color: $color-accent;
        cursor: pointer;
        font-size: 45px;
        transform: rotate(45deg);
        transition: $transition-base;
        position: absolute;
        right: 70px;
        top: 18px;
        @include media-breakpoint-down(sm) {
          right: 55px;
        }
      }
    }
  }
}
