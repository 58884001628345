body {
  font-family: 'Gilroy-Light';
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: $color-text;
  overflow-x: hidden;
  //1219
  @include media-breakpoint-down(lg) {
  }
  //991
  @include media-breakpoint-down(md) {
  }
  //767
  @include media-breakpoint-down(sm) {
  }
  //575
  @include media-breakpoint-down(xs) {
  }
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Gilroy-ExtraBold';
  font-weight: normal;
}

h1 {
  font-size: 45px;
  line-height: 50px;
  @include media-breakpoint-down(sm) {
    font-size: 35px;
    line-height: 40px;
  }
}
h2 {
  font-size: 35px;
  line-height: 35px;
  @include media-breakpoint-down(sm) {
    font-size: 30px;
    line-height: 30px;
  }
}
h3 {
  font-size: 26px;
  line-height: 27px;
  @include media-breakpoint-down(sm) {
    font-size: 20px;
    line-height: 21px;
  }
}
h4 {
  font-size: 18px;
  line-height: 23px;
  @include media-breakpoint-down(sm) {
    font-size: 16px;
    line-height: 21px;
  }
}
h5 {
  font-size: 16px;
  line-height: 21px;
  @include media-breakpoint-down(sm) {
    font-size: 14px;
    line-height: 19px;
  }
}

a {
  color: $color-text-link;
}

img {
  max-width: 100%;
}

* {
  outline: none !important;
  ::selection {
    background: $gray;
    color: white;
  }
}

small{
  font-size: 12px;
  line-height: 17px;
}

.social{
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-right: 11px;
  transition: $transition-base;
  font-size: 16px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  &:hover, &:active, &:focus{
    text-decoration: none;
  }
  &.facebook{
    background-color: $color-primary;
    &:hover, &:active, &:focus{
      background-color: #1796D5;
      box-shadow: $box-shadow-blue;
    }
  }
  &.google{
    background-color: $color-accent;
    &:hover, &:active, &:focus{
      background-color: #B80E80;
      box-shadow: $box-shadow-red;
    }
  }
}

.tile-white {
  background: white;
  border-radius: 41px;
  box-shadow: $box-shadow-blue-large;
  padding: 54px 100px;
  @include media-breakpoint-down(md) {
    padding: 45px 40px;
  }
  @include media-breakpoint-down(xs) {
    padding: 35px 15px;
  }
}

b, strong {
  font-family: 'Gilroy-ExtraBold';
  font-weight: normal;
}
.articles-list, .faq-page, .routes, .reviews-grid-page{
  .footer{
    margin-top: 0;
  }
}

.pimcore_block_buttons {
  z-index: 3;
  position: relative;
}
//styles for search on Home page and boxes page
.homepage, .boxes, .reviews-grid-page, .contact-new {
  .banner{
    padding-bottom: 170px;
    @include media-breakpoint-down(sm) {
      padding-bottom: 120px;
    }
  }
  .banner__content{
    padding: 121px 0 25px;
    @include media-breakpoint-down(lg) {
      padding: 121px 0 60px;
    }
    @include media-breakpoint-down(md) {
      padding: 0 0 70px;
    }
    @include media-breakpoint-down(xs) {
      padding: 0 0 60px;
    }
  }
  .header__nav{
    padding-bottom: 36px;
    @include media-breakpoint-down(md) {
      padding-bottom: 0;
    }
  }
  .search-banner__content{
    margin-top: 0;
  }
  .search-banner{
    margin-bottom: -46px;
    margin-top: 0;
    @include media-breakpoint-down(md) {
      margin-bottom: 0;
    }
  }
  .search-banner__graphic{
    display: none;
  }
}
.routes{
  .search-banner__graphic{
    height: 110px;
    padding-bottom: 0;
    @include media-breakpoint-down(md) {
      height: 100%;
    }
    &::after{
      display: none;
    }
  }
}
.boxes, .reviews-grid-page{
  .banner__content{
    padding: 169px 0 68px;
    @include media-breakpoint-down(md) {
      padding: 0 0 70px;
    }
    @include media-breakpoint-down(xs) {
      padding: 0 0 60px;
    }
  }
  .banner__image{
    text-align: left;
    padding-top: 0px;
    margin-top: -26px;
    @include media-breakpoint-down(lg) {
      padding: 0;
    }
    @include media-breakpoint-down(md) {
      text-align: center;
      margin-top: 0;
      margin-bottom: 30px;
    }
  }
  .banner .inner p{
    @include media-breakpoint-down(sm) {
      margin-bottom: 0;
    }
  }
}
.connections-page, .purchase-page, .tickets-page, .user-details-page {
  .search-banner{
    margin-bottom: 50px;
    @include media-breakpoint-down(xs) {
      margin-bottom: 40px;
    }
    .search-banner__graphic{
      height: 130px;
      &::after{
        display: none;
      }
    }
    .decor{
      display: none;
    }
  }
  .footer{
    margin-top: 80px;
    @include media-breakpoint-down(xs) {
      margin-top: 40px;
    }
  }
}
.disabled-search{
  .search-banner{
    margin-top: 0;
    .bus-search{
      z-index: -1;
      margin-top: -20px;
    }
    @include media-breakpoint-down(md) {
      min-height: 130px;
    }
  }
}

.mile{
  .banner{
    padding-bottom: 220px;
    @include media-breakpoint-down(md){
      display: none;
    }
    &__content{
      min-height: 420px;
      @include media-breakpoint-down(lg){
        padding: 0;
      }
    }
  }
  .search-banner__graphic{
      display: none;
  }
  .info-banner{
    .btn{
      display: none;
    }
  }
  .faq{
    margin-top: 0;
    margin-bottom: 45px;
    @include media-breakpoint-down(md){
      margin-bottom: 0;
    }
  }
  .sidebar{
    display: none;
  }
}

.contact-new{
  .banner{
    padding-top: 80px;
    margin-top: -130px;
    @include media-breakpoint-down('md'){
      display: none;
    }
    &__content{
      min-height: 300px;
    }
    .right-deco{
      left: 69%;
      top: -107px;
      transform: rotate(-93deg);
      font-size: 818px;
    }
    .left-deco{
      font-size: 699px;
      right: 68%;
      bottom: -113px;
    }

    .icon-hero_maziaj_right{
      font-weight: bold;
    }
  }
  .faq{
    margin-top: 0;
    margin-bottom: 0;
    &__wrapper{
      padding-top: 0;
      border-radius: 0;
      background: none;
      margin-top: -15px;
    }
    &__category{
      margin: 0;
    }
    &__item{
      box-shadow: none;
      border-bottom: 1px solid $color-border-faq ;
      border-radius: 0;
      padding-left: 0;
      &+.faq__item{
        margin-top: 0;
      }
      &__text{
        margin: 30px 0 15px;
      }
    }
  }
}

.hide {
  display: none;
}