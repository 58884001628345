.video-popup {
  position: fixed;
  background: white;
  width: 100%;
  height: 100vh;
  z-index: 11;
  left: 0;
  top: 0;
  .container {
    margin: 10vh auto;
    position: relative;
    @include media-breakpoint-down(sm) {
      margin: 50px auto;
    }
    .close {
      position: absolute;
      right: -15px;
      top: -40px;
      font-size: 60px;
      opacity: 1;
      color: $color-accent;
      @include media-breakpoint-down(sm) {
        right: 5px;
        top: -50px;
        font-size: 40px;
      }
      &:hover {
        color: $color-accent-dark;
      }
    }
  }
  .wrapper {
    border-radius: 25px;
    font-size: 0;
    overflow: hidden;
    line-height: 0;
    letter-spacing: 0;
    iframe {
      width: 100%;
      height: 80vh;
    }
  }
}