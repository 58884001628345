@mixin section-with-image($section-name) {
  .#{$section-name}-wrapper {
    h4 {
      font-size: 26px;
      line-height: 35px;
      margin-bottom: 23px;
      margin-top: 40px;
    }
    .#{$section-name} {
      background: transparent;
      display: flex;
      flex-direction: column;
      margin: 0;
      padding-bottom: 0;
      &:last-of-type {
        .sales-point__col {
          padding-bottom: 0 !important;
        }
      }
      &__col {
        display: flex;
        padding-bottom: 30px;
      }
      &__image {
        margin-right: 20px;
        min-width: 107px;
        width: 107px;
        img {
          height: 64px;
          width: 107px;
        }
      }
      &__text {
        color: $color-text;
        display: flex;
        flex-direction: column;
      }
      &__name {
        font-size: 16px;
      }
      &__cities {
        font-size: 14px;
      }
      &__cities-explain {
        font-size: 14px;
        position: relative;
        &:before {
          content: "*";
          display: inline-block;
          left: -7px;
          font-size: 16px;
          position: absolute;
          top: 2px;
        }
      }
    }
  }
}

@mixin section-regular($section-name) {
  .#{$section-name}-wrapper {
    h4 {
      font-size: 26px;
      line-height: 35px;
      margin-bottom: 23px;
      margin-top: 40px;
    }
    .#{$section-name} {
      margin-bottom: 25px;
      max-width: 520px;
      &__title {
        font-size: 16px;
        margin-bottom: 4px;
      }
      &__content {
        font-size: 14px;
        a {
          color: $color-text;
        }
      }
    }
  }
}

.tag-details {
  @extend .news-details;
  &__wrapper {
    @extend .news-details__wrapper;
  }
  &__content {
    @extend .news-details__content;
    max-width: 100%;

    h1 {
      text-align: center;
    }
  }
  &__main-header {
    font-size: 14px;
    line-height: (20px / 14px) * 1em;
    margin: 36px auto 0;
    max-width: 770px;
    text-align: center;
  }
  &__sub-header,
  &__ticket-buy-instructions {
    font-size: 16px;
    margin: 0 auto;
    max-width: 770px;
    line-height: (20px / 16px) * 1em;
    text-align: center;
  }
  &__sub-header {
    margin-top: 36px;
  }
  &__ticket-buy-instructions {
    margin-top: 9px;
    a {
      color: $color-text;
      &:hover {
        color: $color-text;
      }
    }
  }
  &__cols {
    display: flex;
    padding: 90px 0 0 0;
    @include media-breakpoint-down(md) {
      flex-direction: column;
      padding: 15px 0 0 0;
    }
  }
  &__col {
    &.left {
      min-width: 435px;
      width: 435px;
    }
    &.right {
      flex-grow: 2;
      padding-left: 65px;
    }
    @include media-breakpoint-down(md) {
      width: 100% !important;
      min-width: 0 !important;
      &.right {
        padding: 0;
      }
    }
  }
  .how-it-works {
    margin: 0;
    text-align: left;
    border-right: 1px solid #00000029;
    padding-right: 32px;
    padding: 40px;
    @include media-breakpoint-down(md) {
      border: none;
      padding: 0;
    }
    h2 {
      font-size: 26px;
    }
    .how-it-works__step {
      display: flex;
      h4 {
        font-size: 16px;
        margin-bottom: 4px;
        min-height: 0;
        padding: 0;
      }
      p {
        margin: 0;
        font-size: 14px;
      }
    }
    .image {
      background-size: contain;
      background-position: center center;
      height: 105px;
      min-width: 85px;
    }
    .text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 40px;
    }
  }
  .work-space-v2__routes--wrapper {
    height: 114px;
    // margin-right: 32px;
    padding: 19px 12px 19px 15px;
    .work-space-v2__routes {
      font-size: 16px;
      max-height: 90px;
      .route {
        margin-bottom: 11px;
      }
    }
  }
  .work-space-v2__routes-header {
    color: $color-text;
    font-family: "Gilroy-ExtraBold";
    font-size: 26px;
    line-height: 35px;
    padding: 24px 0;
  }
  @include section-with-image(route);
  .route-wrapper {
    & > a {
      &:hover {
        text-decoration: none;
      }
      strong {
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  @include section-with-image(news);
  @include section-with-image(sales-point);
  .news-wrapper {
    .news {
      margin-bottom: 10px;
      &__col {
        padding-bottom: 0;
      }
      a.news__name.news__name.news__name,
      a.news__read-more.news__read-more.news__read-more {
        color: $color-text !important;
      }
    }
  }
  @include section-regular(feedback);
  @include section-regular(faq-details);
  .sales-point-wrapper {
    h4 {
      margin-top: 40px;
    }
    .sales-point {
      & > a {
        &:hover {
          text-decoration: none;
        }
      }
      &__image {
        margin-right: 38px;
        min-width: 60px;
        width: 60px;
        img {
          height: 59px;
          width: 60px;
        }
      }
    }
  }
}
