.header {
  position: relative;
  z-index: 5;
  &__top {
    background: $color-gray-light;
    text-align: right;
    font-size: 0;
    letter-spacing: 0;
    line-height: 0;
    padding: 8px 0;
    @include media-breakpoint-down(md) {
      padding: 8px 0 7px;
    }
    .item {
      display: inline-block;
      vertical-align: top;
      font-size: 11px;
      line-height: 15px;
      letter-spacing: normal;
      margin-left: 20px;
      .ikon-NameMe133_icon {
        font-size: 18px;
        line-height: 11px;
      }
      .info {
        @include media-breakpoint-down(md) {
          display: none;
        }
      }
      .info-mobile {
        display: none;
        @include media-breakpoint-down(md) {
          display: inline;
        }
      }
      .show-info {
        padding-right: 10px;
        position: relative;
        display: block;
        cursor: pointer;
        span {
          @include media-breakpoint-down(md) {
            display: none;
          }
          &.show-info--mobile {
            display: none;
            @include media-breakpoint-down(md) {
              display: inline;
            }
          }
        }
        i {
          font-size: 4px;
          position: absolute;
          right: 0;
          top: 5px;
          transition: $transition-base;
          &.open {
            transform: rotate(180deg);
          }
        }
      }
      a {
        color: $color-primary-dark;
        font-family: "Gilroy-ExtraBold";
        font-weight: normal;
        text-decoration: none;
        &:hover {
          color: $color-primary;
        }
      }
      &.toggle {
        position: relative;
        @include media-breakpoint-down(md) {
          display: inline-block;
          width: 250px;
        }
        @include media-breakpoint-down(xs) {
          width: auto;
          text-align: left;
          margin-left: 0;
          float: left;
        }
        .more-info {
          position: absolute;
          top: 23px;
          right: -10%;
          padding: 25px 27px;
          background: white;
          z-index: 1;
          width: 100%;
          text-align: center;
          box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
          border-radius: 25px;
          line-height: 16px;
          font-size: 14px;
          @include media-breakpoint-down(xs) {
            z-index: 2;
            width: 205px;
            padding: 20px;
            right: auto;
            left: 0;
          }
        }
      }
      &--facebook {
        @include media-breakpoint-down(md) {
          display: none;
        }
        @include media-breakpoint-down(xs) {
          margin-left: 0;
          margin-right: -19px;
          transform: scale(0.7);
        }
        .wrapper {
          width: 111px;
          margin: -3px 0 -2px 0;
        }
      }
    }
  }
  &__nav {
    background: white;
    padding-bottom: 36px;
    @include media-breakpoint-down(md) {
      padding-bottom: 0;
    }
    &.open {
      .switcher {
        & > span {
          &.icon-close {
            visibility: visible;
            opacity: 1;
          }
          &.icon-hamburger {
            visibility: hidden;
            opacity: 0;
          }
        }
      }
    }
    .logo {
      width: 131px;
      text-align: center;
      display: inline-block;
      vertical-align: top;
      @include media-breakpoint-down(md) {
        width: 95px;
      }
      a {
        display: block;
        position: relative;
        img {
          display: inline-block;
          vertical-align: top;
          max-width: 131px;
          margin-top: 16px;
          margin-bottom: -20px;
          position: relative;
          z-index: 1;
          @include media-breakpoint-down(md) {
            max-width: 82px;
            margin-top: 6px;
            margin-bottom: 9px;
          }
        }
      }
    }
    .mobile-container {
      display: none;
      float: right;
      position: relative;
      @include media-breakpoint-down(md) {
        display: block;
      }
    }
    .switcher {
      display: none;
      float: right;
      position: relative;
      width: 39px;
      height: 31px;
      top: 10px;
      @include media-breakpoint-down(md) {
        display: block;
      }
      em {
        position: absolute;
        bottom: -20px;
        left: 0;
        font-size: 13px;
        width: 100%;
        display: block;
        text-align: center;
        font-style: normal;
        font-family: "Gilroy-ExtraBold";
      }
      & > span {
        position: absolute;
        right: 0;
        top: 0;
        font-size: 30px;
        cursor: pointer;
        transform: scale(0.9);
        &.icon-close {
          right: 4px;
          visibility: hidden;
          opacity: 0;
          transition: $transition-base;
        }
        &.icon-hamburger {
          transition: $transition-base;
        }
      }
    }
    .menu-mobile {
      float: right;
      ul {
        margin: 0;
        @include media-breakpoint-down(md) {
          margin: 22px 8px 0 0px;
        }
        @include media-breakpoint-down(xs) {
          margin: 22px 8px 0 0px;
        }
        li {
          margin-left: 40px;
          margin-right: 5px;
          @include media-breakpoint-down(lg) {
            margin-left: 15px;
          }
          @include media-breakpoint-down(md) {
            margin: 0 0 0 11px;
            width: 98px;
            text-align: center;
          }
          @include media-breakpoint-down(xs) {
            margin: 0 0 0 6px;
            width: 80px;
          }
          &:first-child {
            @include media-breakpoint-down(md) {
              width: 50px;
            }
            @include media-breakpoint-down(xs) {
              margin-left: 0;
              width: 40px;
            }
          }
          &.current {
            a {
              color: $color-primary-dark;
            }
          }
          a {
            font-size: 16px;
            margin-top: 46px;
            color: $color-text;
            font-family: "Gilroy-ExtraBold";
            font-weight: normal;
            display: block;
            @include media-breakpoint-down(md) {
              font-size: 12px;
              line-height: 12px;
              margin-top: 0;
            }
            @include media-breakpoint-down(xs) {
              font-size: 10px;
            }
            &:hover,
            &:focus,
            &:active {
              color: $color-primary-dark;
              text-decoration: none;
            }
          }
        }
      }
    }
    .menu {
      float: right;
      @include media-breakpoint-down(md) {
        display: none;
        float: none;
      }
      ul {
        margin: 0;
        @include media-breakpoint-down(md) {
          padding-bottom: 42px;
        }
        li {
          margin-left: 40px;
          @include media-breakpoint-down(lg) {
            margin-left: 15px;
          }
          @include media-breakpoint-down(md) {
            margin: 0;
            display: block;
            text-align: center;
          }
          &.current {
            a {
              color: $color-primary-dark;
            }
          }
          &.tickets {
            @include media-breakpoint-down(md) {
              margin-top: 20px;
            }
            a {
              color: $color-accent;
              &:hover {
                color: $color-accent-dark;
              }
            }
          }
          a {
            font-size: 16px;
            color: $color-text;
            font-family: "Gilroy-ExtraBold";
            font-weight: normal;
            margin-top: 46px;
            display: block;
            @include media-breakpoint-down(md) {
              padding: 0;
              margin: 22px 0 8px;
              display: inline-block;
            }
            &:hover,
            &:focus,
            &:active {
              color: $color-primary-dark;
              text-decoration: none;
            }
          }
        }
      }
    }
  }
  .language-select {
    &.for-mobile {
      display: none;
    }
    @include media-breakpoint-down(md) {
      &.for-desktop {
        display: none;
      }
      &.for-mobile {
        display: block;
      }
    }
    .language-switcher {
      background: white;
      border-radius: 25px;
      box-shadow: 0 3px 5px #8e8e8e;
      display: inline-block;
      font-size: 0;
      .lang {
        color: $color-text-accents;
        border-radius: 28px;
        display: inline-block;
        font-size: 13px;
        line-height: 13px;
        margin: 0;
        padding: 7px;
        text-align: center;
        width: 28px;
        &.active {
          color: $color-accent;
          background: #e9f4fe;
        }
      }
    }
  }
}
