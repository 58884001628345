@font-face {
  font-family: 'Gilroy-ExtraBold';
  src: url('./../fonts/Gilroy-ExtraBold.eot?#iefix') format('embedded-opentype'),  url('./../fonts/Gilroy-ExtraBold.otf')  format('opentype'),
  url('./../fonts/Gilroy-ExtraBold.woff') format('woff'), url('./../fonts/Gilroy-ExtraBold.ttf')  format('truetype'), url('./../fonts/Gilroy-ExtraBold.svg#Gilroy-ExtraBold') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy-Bold';
  src: url('./../fonts/Gilroy-Bold.eot?#iefix') format('embedded-opentype'),  url('./../fonts/Gilroy-Bold.otf')  format('opentype'),
  url('./../fonts/Gilroy-Bold.woff') format('woff'), url('./../fonts/Gilroy-Bold.ttf')  format('truetype'), url('./../fonts/Gilroy-Bold.svg#Gilroy-Bold') format('svg');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'Gilroy-Light';
  src: url('./../fonts/Gilroy-Light.eot?#iefix') format('embedded-opentype'),  url('./../fonts/Gilroy-Light.otf')  format('opentype'),
  url('./../fonts/Gilroy-Light.woff') format('woff'), url('./../fonts/Gilroy-Light.ttf')  format('truetype'), url('./../fonts/Gilroy-Light.svg#Gilroy-Light') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'icomoon';
  src:  url('./../fonts/icomoon.eot?3aysbi');
  src:  url('./../fonts/icomoon.eot?3aysbi#iefix') format('embedded-opentype'),
  url('./../fonts/icomoon.ttf?3aysbi') format('truetype'),
  url('./../fonts/icomoon.woff?3aysbi') format('woff'),
  url('./../fonts/icomoon.svg?3aysbi#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}


[class^="ikon-"], [class*=" ikon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-close .path1:before {
  content: "\e961";
  color: rgb(0, 138, 207);
}
.icon-close .path2:before {
  content: "\e962";
  margin-left: -1em;
  color: rgb(11, 187, 239);
}
.icon-close .path3:before {
  content: "\e963";
  margin-left: -1em;
  color: rgb(0, 138, 207);
}
.icon-close .path4:before {
  content: "\e964";
  margin-left: -1em;
  color: rgb(11, 187, 239);
}
.icon-hamburger .path1:before {
  content: "\e965";
  color: rgb(0, 138, 207);
}
.icon-hamburger .path2:before {
  content: "\e966";
  margin-left: -1.291015625em;
  color: rgb(11, 187, 239);
}
.icon-hamburger .path3:before {
  content: "\e967";
  margin-left: -1.291015625em;
  color: rgb(0, 138, 207);
}
.icon-hamburger .path4:before {
  content: "\e968";
  margin-left: -1.291015625em;
  color: rgb(11, 187, 239);
}
.icon-hamburger .path5:before {
  content: "\e969";
  margin-left: -1.291015625em;
  color: rgb(0, 138, 207);
}
.icon-hamburger .path6:before {
  content: "\e96a";
  margin-left: -1.291015625em;
  color: rgb(11, 187, 239);
}
.icon-downloadr_ico:before {
  content: "\e960";
  color: #b80e80;
}
.icon-arrow_button:before {
  content: "\e900";
  color: #fff;
}
.icon-arrow_down:before {
  content: "\e901";
  color: #e8308a;
}
.icon-button_arrow_pink:before {
  content: "\e902";
  color: #e8308a;
}
.icon-exchange_arrows_blue .path1:before {
  content: "\e903";
  color: rgb(11, 187, 239);
}
.icon-exchange_arrows_blue .path2:before {
  content: "\e904";
  margin-left: -0.892578125em;
  color: rgb(0, 138, 207);
}
.icon-exchange_arrows_pink .path1:before {
  content: "\e905";
  color: rgb(232, 48, 138);
}
.icon-exchange_arrows_pink .path2:before {
  content: "\e906";
  margin-left: -0.892578125em;
  color: rgb(184, 14, 128);
}
.icon-facebook:before {
  content: "\e907";
  color: #fff;
}
.icon-faq_maziaj .path1:before {
  content: "\e908";
  color: rgb(184, 14, 128);
}
.icon-faq_maziaj .path2:before {
  content: "\e909";
  margin-left: -1.2880859375em;
  color: rgb(232, 48, 138);
}
.icon-faq_maziaj .path3:before {
  content: "\e90a";
  margin-left: -1.2880859375em;
  color: rgb(232, 48, 138);
}
.icon-google_plus .path1:before {
  content: "\e90b";
  color: rgb(220, 78, 65);
}
.icon-google_plus .path2:before {
  content: "\e90c";
  margin-left: -1em;
  color: rgb(220, 78, 65);
}
.icon-google_plus .path3:before {
  content: "\e90d";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-google_plus .path4:before {
  content: "\e90e";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-hero_maziaj_left .path1:before {
  content: "\e90f";
  color: rgb(0, 138, 207);
}
.icon-hero_maziaj_left .path2:before {
  content: "\e910";
  margin-left: -1.083984375em;
  color: rgb(0, 138, 207);
}
.icon-hero_maziaj_left .path3:before {
  content: "\e911";
  margin-left: -1.083984375em;
  color: rgb(5, 144, 198);
}
.icon-hero_maziaj_left .path4:before {
  content: "\e912";
  margin-left: -1.083984375em;
  color: rgb(11, 187, 239);
}
.icon-hero_maziaj_left .path5:before {
  content: "\e913";
  margin-left: -1.083984375em;
  color: rgb(11, 187, 239);
}
.icon-hero_maziaj_right .path1:before {
  content: "\e914";
  color: rgb(0, 138, 207);
}
.icon-hero_maziaj_right .path2:before {
  content: "\e915";
  margin-left: -0.7783203125em;
  color: rgb(0, 138, 207);
}
.icon-hero_maziaj_right .path3:before {
  content: "\e916";
  margin-left: -0.7783203125em;
  color: rgb(11, 187, 239);
}
.icon-hero_maziaj_right .path4:before {
  content: "\e917";
  margin-left: -0.7783203125em;
  color: rgb(11, 187, 239);
}
.icon-how_it_works_1 .path1:before {
  content: "\e918";
  color: rgb(233, 244, 254);
}
.icon-how_it_works_1 .path2:before {
  content: "\e919";
  margin-left: -0.7919921875em;
  color: rgb(233, 244, 254);
}
.icon-how_it_works_1 .path3:before {
  content: "\e91a";
  margin-left: -0.7919921875em;
  color: rgb(5, 144, 198);
}
.icon-how_it_works_1 .path4:before {
  content: "\e91b";
  margin-left: -0.7919921875em;
  color: rgb(5, 144, 198);
}
.icon-how_it_works_1 .path5:before {
  content: "\e91c";
  margin-left: -0.7919921875em;
  color: rgb(5, 144, 198);
}
.icon-how_it_works_1 .path6:before {
  content: "\e91d";
  margin-left: -0.7919921875em;
  color: rgb(5, 144, 198);
}
.icon-how_it_works_1 .path7:before {
  content: "\e91e";
  margin-left: -0.7919921875em;
  color: rgb(5, 144, 198);
}
.icon-how_it_works_1 .path8:before {
  content: "\e91f";
  margin-left: -0.7919921875em;
  color: rgb(5, 144, 198);
}
.icon-how_it_works_1 .path9:before {
  content: "\e920";
  margin-left: -0.7919921875em;
  color: rgb(232, 48, 138);
}
.icon-how_it_works_1 .path10:before {
  content: "\e921";
  margin-left: -0.7919921875em;
  color: rgb(232, 48, 138);
}
.icon-how_it_works_1 .path11:before {
  content: "\e922";
  margin-left: -0.7919921875em;
  color: rgb(5, 144, 198);
}
.icon-how_it_works_1 .path12:before {
  content: "\e923";
  margin-left: -0.7919921875em;
  color: rgb(5, 144, 198);
}
.icon-how_it_works_1 .path13:before {
  content: "\e924";
  margin-left: -0.7919921875em;
  color: rgb(5, 144, 198);
}
.icon-how_it_works_2 .path1:before {
  content: "\e925";
  color: rgb(233, 244, 254);
}
.icon-how_it_works_2 .path2:before {
  content: "\e926";
  margin-left: -0.869140625em;
  color: rgb(233, 244, 254);
}
.icon-how_it_works_2 .path3:before {
  content: "\e927";
  margin-left: -0.869140625em;
  color: rgb(5, 144, 198);
}
.icon-how_it_works_2 .path4:before {
  content: "\e928";
  margin-left: -0.869140625em;
  color: rgb(232, 48, 138);
}
.icon-how_it_works_2 .path5:before {
  content: "\e929";
  margin-left: -0.869140625em;
  color: rgb(5, 144, 198);
}
.icon-how_it_works_2 .path6:before {
  content: "\e92a";
  margin-left: -0.869140625em;
  color: rgb(5, 144, 198);
}
.icon-how_it_works_2 .path7:before {
  content: "\e92b";
  margin-left: -0.869140625em;
  color: rgb(232, 48, 138);
}
.icon-how_it_works_2 .path8:before {
  content: "\e92c";
  margin-left: -0.869140625em;
  color: rgb(5, 144, 198);
}
.icon-how_it_works_3 .path1:before {
  content: "\e92d";
  color: rgb(233, 244, 254);
}
.icon-how_it_works_3 .path2:before {
  content: "\e92e";
  margin-left: -0.7109375em;
  color: rgb(233, 244, 254);
}
.icon-how_it_works_3 .path3:before {
  content: "\e92f";
  margin-left: -0.7109375em;
  color: rgb(232, 48, 138);
}
.icon-how_it_works_3 .path4:before {
  content: "\e930";
  margin-left: -0.7109375em;
  color: rgb(232, 48, 138);
}
.icon-how_it_works_3 .path5:before {
  content: "\e931";
  margin-left: -0.7109375em;
  color: rgb(5, 144, 198);
}
.icon-how_it_works_3 .path6:before {
  content: "\e932";
  margin-left: -0.7109375em;
  color: rgb(5, 144, 198);
}
.icon-how_it_works_3 .path7:before {
  content: "\e933";
  margin-left: -0.7109375em;
  color: rgb(232, 48, 138);
}
.icon-how_it_works_3 .path8:before {
  content: "\e934";
  margin-left: -0.7109375em;
  color: rgb(5, 144, 198);
}
.icon-how_it_works_3 .path9:before {
  content: "\e935";
  margin-left: -0.7109375em;
  color: rgb(5, 144, 198);
}
.icon-how_it_works_4 .path1:before {
  content: "\e936";
  color: rgb(233, 244, 254);
}
.icon-how_it_works_4 .path2:before {
  content: "\e937";
  margin-left: -0.8779296875em;
  color: rgb(233, 244, 254);
}
.icon-how_it_works_4 .path3:before {
  content: "\e938";
  margin-left: -0.8779296875em;
  color: rgb(5, 144, 198);
}
.icon-how_it_works_4 .path4:before {
  content: "\e939";
  margin-left: -0.8779296875em;
  color: rgb(5, 144, 198);
}
.icon-how_it_works_4 .path5:before {
  content: "\e93a";
  margin-left: -0.8779296875em;
  color: rgb(232, 48, 138);
}
.icon-logo_hoper .path1:before {
  content: "\e93b";
  color: rgb(4, 186, 238);
}
.icon-logo_hoper .path2:before {
  content: "\e93c";
  margin-left: -1.685546875em;
  color: rgb(4, 186, 238);
}
.icon-logo_hoper .path3:before {
  content: "\e93d";
  margin-left: -1.685546875em;
  color: rgb(0, 136, 206);
}
.icon-logo_hoper .path4:before {
  content: "\e93e";
  margin-left: -1.685546875em;
  color: rgb(0, 136, 206);
}
.icon-logo_hoper .path5:before {
  content: "\e93f";
  margin-left: -1.685546875em;
  color: rgb(231, 48, 136);
}
.icon-logo_hoper .path6:before {
  content: "\e940";
  margin-left: -1.685546875em;
  color: rgb(231, 48, 136);
}
.icon-logo_hoper .path7:before {
  content: "\e941";
  margin-left: -1.685546875em;
  color: rgb(190, 8, 127);
}
.icon-logo_hoper .path8:before {
  content: "\e942";
  margin-left: -1.685546875em;
  color: rgb(183, 13, 128);
}
.icon-logo_hoper .path9:before {
  content: "\e943";
  margin-left: -1.685546875em;
  color: rgb(72, 81, 86);
}
.icon-logo_hoper .path10:before {
  content: "\e944";
  margin-left: -1.685546875em;
  color: rgb(72, 81, 86);
}
.icon-logo_hoper .path11:before {
  content: "\e945";
  margin-left: -1.685546875em;
  color: rgb(72, 81, 86);
}
.icon-logo_hoper .path12:before {
  content: "\e946";
  margin-left: -1.685546875em;
  color: rgb(72, 81, 86);
}
.icon-logo_hoper .path13:before {
  content: "\e947";
  margin-left: -1.685546875em;
  color: rgb(72, 81, 86);
}
.icon-phone_icon:before {
  content: "\e948";
  color: #e8308a;
}
.ikon-NameMe133_icon {
  &:before {
    content: "\e074";
    color: #e8308a;
  }
}
.icon-promocja_icon:before {
  content: "\e949";
  color: #e8308a;
}
.icon-quotation_mark:before {
  content: "\e94a";
  color: #e9f4fe;
}
.icon-wyslij_paczke_maziaj .path1:before {
  content: "\e94b";
  color: rgb(0, 138, 207);
}
.icon-wyslij_paczke_maziaj .path2:before {
  content: "\e94c";
  margin-left: -1.1220703125em;
  color: rgb(0, 138, 207);
}
.icon-wyslij_paczke_maziaj .path3:before {
  content: "\e94d";
  margin-left: -1.1220703125em;
  color: rgb(11, 187, 239);
}
.icon-wyslij_paczke_maziaj .path4:before {
  content: "\e94e";
  margin-left: -1.1220703125em;
  color: rgb(11, 187, 239);
}
.icon-zalety_hopera_1:before {
  content: "\e94f";
  color: #0bbbef;
}
.icon-zalety_hopera_2:before {
  content: "\e950";
  color: #0bbbef;
}
.icon-zalety_hopera_3:before {
  content: "\e951";
  color: #0bbbef;
}
.icon-zalety_hopera_4:before {
  content: "\e952";
  color: #0bbbef;
}
.icon-zalety_hopera_5:before {
  content: "\e953";
  color: #0bbbef;
}
.icon-zalety_hopera_6:before {
  content: "\e954";
  color: #0bbbef;
}
.icon-zalety_hopera_7:before {
  content: "\e955";
  color: #0bbbef;
}
.icon-zalety_hopera_8:before {
  content: "\e956";
  color: #0bbbef;
}
.icon-zalety_maziaj_left .path1:before {
  content: "\e957";
  color: rgb(0, 138, 207);
}
.icon-zalety_maziaj_left .path2:before {
  content: "\e958";
  margin-left: -1.0048828125em;
  color: rgb(0, 138, 207);
}
.icon-zalety_maziaj_left .path3:before {
  content: "\e959";
  margin-left: -1.0048828125em;
  color: rgb(5, 144, 198);
}
.icon-zalety_maziaj_left .path4:before {
  content: "\e95a";
  margin-left: -1.0048828125em;
  color: rgb(11, 187, 239);
}
.icon-zalety_maziaj_left .path5:before {
  content: "\e95b";
  margin-left: -1.0048828125em;
  color: rgb(11, 187, 239);
}
.icon-zalety_maziaj_right .path1:before {
  content: "\e95c";
  color: rgb(0, 138, 207);
}
.icon-zalety_maziaj_right .path2:before {
  content: "\e95d";
  margin-left: -1.373046875em;
  color: rgb(0, 138, 207);
}
.icon-zalety_maziaj_right .path3:before {
  content: "\e95e";
  margin-left: -1.373046875em;
  color: rgb(11, 187, 239);
}
.icon-zalety_maziaj_right .path4:before {
  content: "\e95f";
  margin-left: -1.373046875em;
  color: rgb(11, 187, 239);
}