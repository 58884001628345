/*
 *  Owl Carousel - Animate Plugin
 */
.owl-carousel{
	.animated {
		animation-duration: 2000ms;
		animation-fill-mode: both;
	}
	.owl-animated-in {
		z-index: 0;
	}
	.owl-animated-out {
		z-index: 1;
	}
	.fadeInLeft {
		animation-name: fadeInLeft;
	}
	.fadeOutRight {
		animation-name: fadeOutRight;
	}
}

@keyframes fadeInLeft {
	from {
		opacity: 0;
		transform: translate3d(-100%, 0, 0);
	}

	to {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}
@keyframes fadeOutRight {
	from {
		opacity: 1;
	}

	to {
		opacity: 0;
		transform: translate3d(100%, 0, 0);
	}
}