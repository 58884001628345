.recommend-start {
  padding: 200px 0 0;
  margin: -62px 0 0 0;

  @include media-breakpoint-down(xs) {
    padding: 120px 0 0;
  }

  .container .tile-white {
    padding: 54px 100px;

    @include media-breakpoint-down(md) {
      padding: 45px 35px;
    }

    @include media-breakpoint-down(xs) {
      padding: 35px 15px;
    }

    .icon {
      max-height: 120px;
      margin-bottom: 25px;
    }

    h6 {
      font-family: 'Gilroy-Light';
      margin: 10px 0 83px;

      @include media-breakpoint-down(md) {
        margin: 10px 0;
      }
    }
  }
}