.news-list{
  margin: -236px 0 0;
  text-align: center;
  position: relative;
  z-index: 3;
  background: linear-gradient(to bottom, transparent 0%, #E9F4FE 100%);
  @include media-breakpoint-down(md) {
    margin-top: 0;
  }
  &__content{
    padding: 68px 58px 0;
    border-radius: 41px;
    background: linear-gradient(to bottom, white 0%, #E9F4FE 100%);
    @include media-breakpoint-down(md) {
      border-radius: 0;
      padding: 68px 0 0;
    }
    @include media-breakpoint-down(sm) {
      padding-top: 50px;
    }
  }
  h1{
    margin-bottom: 68px;
    font-size: 35px;
    line-height: 35px;
    @include media-breakpoint-down(sm) {
      margin-bottom: 48px;
      font-size: 30px;
      line-height: 30px;
    }
  }
  &__item{
    margin-bottom: 67px;
    @include media-breakpoint-down(sm) {
      max-width: 380px;
      margin: 0 auto 25px;
    }
    &__image{
      display: block;
      position: relative;
      border-radius: 11px;
      overflow: hidden;
      height: 195px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      box-shadow: 5px 5px 10px 0 rgba(232, 48, 138, 0);
      transition: $transition-base;
      &::after{
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: $color-accent;
        opacity: 0;
        transition: $transition-base;
      }
      &:hover{
        box-shadow: 5px 5px 10px 0 rgba(232, 48, 138, 0.3);
        &::after {
          opacity: .5;
        }
      }
      img{
        width: 100%;
      }
    }
    &__title{
      line-height: 23px;
      margin-top: 21px;
      margin-bottom: 3px;
      a{
        color: $color-text;
        transition: $transition-base;
        &:hover{
          color: $color-accent;
          text-decoration: none;
        }
      }
    }
    &__date{
      display: block;
      color: #A2A7A9;
      font-size: 11px;
      line-height: 17px;
      margin-bottom: 1px;
    }
    &__description{
      display: block;
      font-size: 14px;
      line-height: 20px;
      margin-top: 10px;
    }
  }
}