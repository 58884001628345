.navigation-routes {
  @include media-breakpoint-down(sm) {
    padding-bottom: 15px;
  }

  .container {
    position: static;
  }

  &__panel {
    position: absolute;
    top: 42px;
    z-index: 1;
    width: 370px;
    background-color: $color-white;
    border-radius: 25px;
    max-height: none;
    overflow: hidden;
    padding-bottom: 15px;
    @include media-breakpoint-down(lg) {
      width: 320px;
    }
    @include media-breakpoint-down(md) {
      //max-height: 550px;
      width: 280px;
    }
    @include media-breakpoint-down(sm) {
      //max-height: 350px;
      width: 100%;
      //margin-bottom: 40px;
      position: static;
      background-color: transparent;
      border-radius: 0;

      margin-bottom: 0;
      overflow: visible;
    }

    .navigation-routes__panel__content {
      min-height: 633px;
      display: flex;
      flex-direction: column;
      @include media-breakpoint-down(lg) {
        min-height: 694px;
      }
      @include media-breakpoint-down(md) {
        min-height: 550px;
      }
      @include media-breakpoint-down(sm) {
        min-height: 200px;
      }

      .scroll-pane {
        flex: 1 1 auto;
      }
    }

    &--international {
      .route-cities-inner {
        ul {

          &::after {
            display: none;
          }

          li {
            &::before {
              display: none;
            }
          }
        }

        > ul {
          padding: 0 8px;
          margin-left: 0;

          > li {
            border-bottom: 1px solid $color-text-small;
            color: $color-text;
            font-family: 'Gilroy-ExtraBold';

            &:first-child {
              color: $color-text;
            }
          }

          ul {
            padding: 0;
            column-count: 2;
            margin: 11px 0 12px 5px;

            li {
              list-style-type: disc;
              margin-left: 10px;
            }

            li {
              &:first-child, &:last-child {
                color: $color-text-small;
                font-weight: normal;
                font-family: 'Gilroy-Light';

                &::after {
                  display: none;
                }
              }
            }
          }
        }
      }
    }

    .description {
      font-weight: normal;
      font-size: 13px;
      line-height: 1.4;

      a {
        font-family: 'Gilroy-ExtraBold';
        transition: $transition-base;
      }

      &--bottom {
        padding: 20px 11px 10px 25px;
        border-top: 1px solid #A2A7A9;
        @include media-breakpoint-down(sm) {
          display: none;
        }

        p {
          margin-bottom: 0;
        }
      }
    }

    &__title {
      border-radius: 25px 25px 0 0;
      background-color: $color-primary-dark;
      color: $color-white;
      text-align: center;
      padding: 26px 10px 19px;
      @include media-breakpoint-down(sm) {
        text-align: left;
        color: $color-text;
        padding: 0;
        background-color: transparent;
        margin-top: 52px;
      }

      &__content {
        font-size: 26px;
        line-height: 27px;
        @include media-breakpoint-down(sm) {
          font-size: 30px;
          line-height: 30px;
        }
      }
    }

    &__content {
      padding: 23px 11px 23px 25px;
      @include media-breakpoint-down(sm) {
        padding: 23px 0 0 0;
      }
    }

    &__item {
      h4 {
        cursor: pointer;
        margin-right: 40px;
        transition: $transition-base;
        opacity: 1;

        &:hover {
          color: $color-primary;

          i {
            &::before {
              color: $color-primary;
            }
          }
        }

        i {
          font-size: 6px;
          display: inline-block;
          margin-left: 12px;
          position: relative;
          top: -2px;

          &::before {
            transition: $transition-base;
            color: $color-text;
          }
        }
      }

      .download {
        display: inline-block;
        font-family: 'Gilroy-ExtraBold';
        font-size: 12px;
        line-height: 17px;
        text-decoration: none;

        span {
          text-decoration: underline;
        }

        i {
          font-size: 10px;
          margin-right: 5px;
        }
      }

      ul {
        list-style-type: none;
        margin: 13px 0 16px 4px;
        padding-left: 19px;
        position: relative;

        &::after {
          content: '';
          background-color: $color-primary;
          height: 100%;
          width: 3px;
          position: absolute;
          left: 0;
          top: 4px;
        }

        li {
          font-size: 14px;
          line-height: 18px;
          color: #A2A7A9;

          &:first-child, &:last-child {
            color: $color-primary-dark;
            font-family: 'Gilroy-ExtraBold';
            position: relative;

            &::before {
              content: '';
              background-color: $color-primary-dark;
              width: 11px;
              height: 11px;
              position: absolute;
              left: -23px;
              border-radius: 50%;
              top: 50%;
              transform: translateY(-50%);
              z-index: 2;
            }
          }

          &:last-child {
            &::after {
              content: '';
              background-color: $color-white;
              width: 11px;
              position: absolute;
              height: 20px;
              left: -23px;
              bottom: -15px;
              z-index: 1;
            }
          }
        }
      }
    }
  }

  &__message {
    position: absolute;
    bottom: 70px;

    z-index: 1;
    background-color: $color-white;
    border-radius: 10px;
    padding: 25px;
    max-height: none;
    overflow: hidden;
    padding-bottom: 15px;
    margin-left: 430px;
    max-width: 740px;
    @include media-breakpoint-down(lg) {
      margin-left: 360px;
      max-width: 570px;
    }
    @include media-breakpoint-down(md) {
      margin-left: 300px;
      max-width: 390px;
    }
    @include media-breakpoint-down(sm) {
      width: 100%;
      max-width: 100%;
      position: static;
      background-color: transparent;
      border-radius: 0;
      margin: 20px 0 40px;
      padding: 0;
    }
    &__title {
      font-size: 22px;
      line-height: (30px/22px) * 1em;
    }
  }

  .scroll-pane {
    //height: 517px;
    //@include media-breakpoint-down(md) {
    //  height: 400px;
    //}
    //@include media-breakpoint-down(sm) {
    //  height: 250px;
    //}
    min-height: 200px;
  }

  .route-cities {
    width: 100%;
    color: $color-text;

    .route-cities-inner {
      display: none;
    }

    &.selected {
      h4 {
        color: $color-accent;
      }
    }

    &.close {
      opacity: 1;
      float: none;
      color: $color-text;
    }

    &.open {
      .route-cities-inner {
        display: block;
      }

      h4 {
        color: $color-primary;

        i {
          &::before {
            color: $color-primary;
            transform: rotate(180deg);
            display: inline-block;
          }
        }
      }
    }
  }
  &__search {
    border: 1px solid #cccccc;
    border-radius: 35px;
    height: 55px;
    margin: 19px 0;
    max-width: 487px;
    padding: 18px 32px;
    position: relative;
    width: 100%;
    &__input {
      border: none;
      height: 19px;
      font-size: 16px;
      line-height: 19px;
      width: 100%;
    }
    &__icon {
      &--search {
        cursor: pointer;
        position: absolute;
        right: 26px;
        top: 18px;
      }
      &--clear {
        color: $color-accent;
        cursor: pointer;
        font-size: 45px;
        transform: rotate(45deg);
        transition: $transition-base;
        position: absolute;
        right: 70px;
        top: 18px;
        @include media-breakpoint-down(sm) {
          right: 55px;
        }
      }
    }
  }
}
.modal {
  .go-back-to-searcher {
    padding: 12px 21px;
  }
}