.ep-modal-wrapper {
  background: transparentize(#000, 0.6);
  box-sizing: border-box !important;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  overflow: auto;
  font-size: 14px;
  display: none;
  * {
    box-sizing: border-box !important;
  }
  &.is-loading {
    .ep-modal-footer {
      display: none;
    }
  }
  &.wide {
    .ep-modal-dialog {
      @include media-breakpoint-down(md) {
        width: 1200px;
      }
      @include media-breakpoint-down(lg) {
        width: 1200px;
      }
      @media screen and (max-width: 1200px) {
        width: 90%;
      }
    }
  }
  &.narrow {
    .ep-modal-dialog {
      @include media-breakpoint-down(md) {
        width: 768px;
      }
      @include media-breakpoint-down(lg) {
        width: 768px;
      }
      @media screen and (max-width: 768px) {
        width: 90%;
      }
    }
  }
  .ep-modal-dialog {
    display: block;
    margin: 0 auto;
    width: 90%;
    padding: 30px 0;
    @include media-breakpoint-down(md) {
      width: 800px;
    }

    @include media-breakpoint-down(lg) {
      width: 1000px;
    }
    .ep-modal {
      border-radius: 5px;
      background: $white;
      display: block;
      .ep-modal-header {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        background: transparent url("") top left
          repeat-x;
        padding: 20px 15px 15px;
        font-size: 18px;
        overflow: hidden;
        clear: both;
        .ep-modal-close {
          cursor: pointer;
          float: right;
          font-size: 22px;
          line-height: 1em;
        }
      }
      .ep-modal-content {
        font-size: 14px;
        border-top: 1px solid #dcdbd6;
        border-bottom: 1px solid #dcdbd6;
        padding: 15px;
        .ep-loader-wrapper {
          display: block;
          width: 100%;
          text-align: center;
          padding: 10px 0;
        }
      }
      .ep-modal-footer {
        padding: 15px;
        text-align: right;
      }
    }
  }
  .btn {
    .arrow {
      display: inline-block;
      height: 7px;
      margin: 0 0 3px 5px;
    }
  }
}

body {
  &.ep-modal-opened {
    overflow: hidden;
  }
}

.ep-grid {
  display: block;
  margin: 0 -10px;
  display: flex;
  &.ep-grid-direction-column {
    flex-direction: column;
  }
}
.ep-grid-col {
  display: block;
  padding: 0 10px;
  width: 100%;
  &.ep-grid-col-3-4 {
    width: (3/4) * 100%;
  }
  &.ep-grid-col-1-4 {
    width: (1/4) * 100%;
  }
  @for $i from 1 to 12 {
    &.ep-grid-col-#{$i} {
      width: ($i/12) * 100%;
    }
  }
}
